import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { appLogo } from "../../appConfig.js";
import { minimizedLogo } from "../../appConfig.js";
import menuToggle from "../../assets/img/menuToggle.svg";
import { getData } from "utils/util.js";
import { Image } from "components/Images/Image.jsx";
import { NEW_SURVEY_DATA } from "actionTypes/survey_action_type.js";

import {
  updateUserQueries,
  getUserProfile,
  fetchUserModuleRole,
  editOrganizationName,
} from "../../actions/user_action.js";
import { updateAuditQueries } from "../../actions/audit_action.js";

import ConfirmationModel from "components/Common/LTA_Models/ConfirmationModel.jsx";
import { logout } from "actions/admin_action.js";
import { useDetectClickOutside } from "react-detect-click-outside";
import { STOP_SCROLL } from "actionTypes/keppel_action_type.js";

const SidebarNew = (props) => {
  const [editOrganization, setEditOrganization] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);

  const [state, setState] = useState({
    showSurvey: false,
    showAuditLog: false,
    showEditSurvey: false,
    showCreateSurvey: false,
    showResponses: false,
    showIncentive: false,
    showAnalytics: false,
    showSettings: false,
    showRouteChangePopUp: false,
    callBack: "",
    is_superadmin: false,
    showSecondary: false,
    showInterviewers: false,
  });
  const dispatch = useDispatch();
  const newSurveyData = useSelector(
    (state) => state?.survey_reducer?.newSurveyData
  );
  const handleLogout = () => {
    dispatch(logout(props.history));
  };

  const onCloseSidebar = () => {
    dispatch({
      type: STOP_SCROLL,
      payload: false,
    });
    setMobileSidebarOpen(false);
  };
  const ref = useDetectClickOutside({ onTriggered: onCloseSidebar });

  const { data = {} } = getData("userProfile");

  const updateOrganizationName = () => {
    if (organizationName) {
      dispatch(
        editOrganizationName(
          {
            id: `${data.organization.id}`,
            name: organizationName,
          },
          (orgName) => {
            setOrganizationName(orgName);
          }
        )
      );
    }
  };

  useEffect(() => {
    const isSuperAdmin = data && data.is_superadmin;
    setOrganizationName(data?.organization?.name);
    dispatch(fetchUserModuleRole(false, null, props.history)).then((resp) => {
      const response = resp && resp.data && resp.data.data;

      if (isSuperAdmin === "super_admin") {
        setState({
          ...state,
          is_superadmin: isSuperAdmin,
          showSurvey: true,
          showAuditLog: true,
          showEditSurvey: true,
          showResponses: true,
          showIncentive: true,
          showAnalytics: true,
          showSettings: true,
          showInterviewers: true,
          showCreateSurvey: true,
        });
      } else if (isSuperAdmin !== "super_admin") {
        const surveyPermission =
          (response && response.filter((mod) => mod.module_id === 3)) || [];
        const responsePermission =
          (response && response.filter((mod) => mod.module_id === 4)) || [];
        const analyticsPermission =
          (response && response.filter((mod) => mod.module_id === 6)) || [];
        const incentivePermission =
          (response &&
            response.filter(
              (mod) => mod.module_short_description === "incentive_logs"
            )) ||
          [];
        const auditLogPermission =
          (response && response.filter((mod) => mod.module_id === 2)) || [];
        const settingsPermission =
          (response && response.filter((mod) => mod.module_id === 8)) || [];
        const InterviewerPermission =
          (response && response.filter((mod) => mod.module_id === 10)) || [];
        const surveyRoleId =
          surveyPermission &&
          surveyPermission[0] &&
          surveyPermission[0].role_id;

        setState({
          ...state,

          is_superadmin: isSuperAdmin,
          showSurvey: surveyPermission[0],
          showAuditLog: auditLogPermission[0],
          showInterviewers: InterviewerPermission[0],
          showCreateSurvey: surveyRoleId === 5 ? true : false,
          showEditSurvey:
            surveyRoleId === 5 || surveyRoleId === 4 || surveyRoleId === 3
              ? true
              : false,
          showSettings: settingsPermission[0],
          showResponses:
            responsePermission && responsePermission.length > 0 ? true : false,
          showAnalytics:
            analyticsPermission && analyticsPermission.length > 0
              ? true
              : false,
          showIncentive:
            incentivePermission && incentivePermission.length > 0
              ? true
              : false,
        });
      }
    });
  }, []);

  return (
    <Navbar>
      <div
        id="new_version"
        className={mobileSidebarOpen ? "MenuOpen" : ""}
        ref={ref}
        
      >
        <button
          className="navbarToggle"
          onClick={() => {
            if (mobileSidebarOpen) {
              dispatch({
                type: STOP_SCROLL,
                payload: false,
              });
            } else {
              dispatch({
                type: STOP_SCROLL,
                payload: true,
              });
            }

            setMobileSidebarOpen(!mobileSidebarOpen);
          }}
        >
          <span class="material-symbols-outlined">menu</span>
        </button>
        <div
          className="sidebar-new Sidebar-drawerPaper-9 show navbar-collapse"
          id="navbarNav"
          style={{ overflow: "auto" }}
        >
          <a href="/" className="side_bar_logo">
            <img src={appLogo} alt="" className="show-lg" />
            <img src={minimizedLogo} alt="" className="show-sm" />
          </a>
          <div className="menu-sec flex-column">
            <ul className="nav nav-pills flex-column Sidebar-list">
              <li className="dropdown">
                <a
                  className="org-sec dropdown-toggle disabled"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <figure className="circleImg">
                    <img src={Image.orgLogo} alt="" />
                  </figure>
                  <div className="d-flex flex-column gap-1">
                    <strong>Organization Name</strong>
                    {editOrganization ? (
                      <>
                        <div className="d-flex orgn">
                          <textarea
                            className="textArea_text"
                            rows={1}
                            value={organizationName}
                            onChange={(e) => {
                              setOrganizationName(e.target.value);
                            }}
                          ></textarea>
                          <button
                            className="saveBtn"
                            onClick={() => {
                              setEditOrganization(false);
                              updateOrganizationName();
                            }}
                          ></button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex orgn">
                          <p className="orgName mb-0">
                            {organizationName.length > 15
                              ? organizationName.slice(0, 15) + "..."
                              : organizationName}
                          </p>

                          <button
                            className="editBtn"
                            onClick={() => setEditOrganization(true)}
                          ></button>
                        </div>
                      </>
                    )}
                  </div>
                </a>

                <ul className="dropdown-menu disable">
                  <li>Action</li>
                  <li>Another action</li>
                  <li>Something else here</li>
                </ul>
              </li>
              {/* <li className="nav-item">
                <Link
                  to="/admin/analytics"
                  className={
                    window.location.href.includes("analytics")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  aria-current="page"
                >
                  <i className="dash-icon"></i>
                  Dashboard
                </Link>
              </li> */}
              {state?.showSurvey && (
                <li
                  className="nav-item"
                  onClick={onCloseSidebar}
                >
                  {window.location.href.includes("/surveys") ? (
                    <Link
                      to="/admin/surveys"
                      onClick={(e) => e.preventDefault()}
                      className={
                        window.location.href.includes("/surveys")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="surveys-icon"></i>
                      Surveys
                    </Link>
                  ) : (
                    <Link
                      to="/admin/surveys"
                      className={
                        window.location.href.includes("/surveys")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="surveys-icon"></i>
                      Surveys
                    </Link>
                  )}
                </li>
              )}
              {state?.is_superadmin && (
                <li
                  onClick={() => {
                    onCloseSidebar()
                    const func = () => {
                      props.history.push("/admin/users");
                      dispatch(
                        updateUserQueries({
                          limit: 25,
                          page: 0,
                          startdate: "",
                          enddate: "",
                          search: "",
                        })
                      );
                    };
                    if (Object.keys(newSurveyData)?.length > 0) {
                      setState({
                        ...state,
                        showRouteChangePopUp: true,
                        callBack: func,
                      });
                    } else {
                      func();
                    }
                  }}
                  className="nav-item"
                >
                  <Link
                    to=""
                    className={
                      window.location.href.includes("user")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <i className="usermg-icon"></i>
                    User Management
                  </Link>
                </li>
              )}
              {state?.showSurvey && (
                <li
                  className="nav-item"
                  onClick={() => {
                    onCloseSidebar()
                    const func = () => {
                      props.history.push("/admin/contacts");
                    };
                    if (Object.keys(newSurveyData)?.length > 0) {
                      setState({
                        ...state,
                        showRouteChangePopUp: true,
                        callBack: func,
                      });
                    } else {
                      func();
                    }
                  }}
                >
                  <Link
                    to=""
                    className={
                      window.location.href.includes("contacts")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <i className="contacts-icon"></i>
                    Contacts
                  </Link>
                </li>
              )}
              {/* {state?.showSurvey && (
                <li className="nav-item">
                  {window.location.href.includes("/surveys") ? (
                    <Link
                      to="/admin/surveys"
                      onClick={(e) => e.preventDefault()}
                      className={
                        window.location.href.includes("/surveys")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="surveys-icon"></i>
                      Surveys
                    </Link>
                  ) : (
                    <Link
                      to="/admin/surveys"
                      className={
                        window.location.href.includes("/surveys")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="surveys-icon"></i>
                      Surveys
                    </Link>
                  )}
                </li>
              )} */}
              {state?.showInterviewers && (
                <li
                  className="nav-item"
                  onClick={() => {
                    onCloseSidebar()
                    const func = () => {
                      props.history.push("/admin/interviewers");
                      dispatch(
                        updateUserQueries({
                          limit: 25,
                          page: 0,
                          startdate: "",
                          enddate: "",
                          search: "",
                        })
                      );
                    };
                    if (Object.keys(newSurveyData)?.length > 0) {
                      setState({
                        ...state,
                        showRouteChangePopUp: true,
                        callBack: func,
                      });
                    } else {
                      func();
                    }
                  }}
                >
                  <Link
                    to=""
                    className={
                      window.location.href.includes("interviewer")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <i className="interviewer-icon"></i>
                    Interviewers
                  </Link>
                </li>
              )}
              {state?.showAuditLog && (
                <li
                  className="nav-item"
                  onClick={() => {
                    onCloseSidebar()
                    const func = () => {
                      props.history.push("/admin/auditlogs");
                      dispatch(
                        updateAuditQueries({
                          limit: 30,
                          page: 1,
                          search: null,
                          status: undefined,
                          dateFrom: "",
                          dateTo: "",
                          action: "",
                          dataTypes: "",
                        })
                      );
                    };
                    if (Object.keys(newSurveyData)?.length > 0) {
                      setState({
                        ...state,
                        showRouteChangePopUp: true,
                        callBack: func,
                      });
                    } else {
                      func();
                    }
                  }}
                >
                  <Link
                    to=""
                    className={
                      window.location.href.includes("auditlogs")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="auditLogs-icon"></i>
                    Audit Logs
                  </Link>
                </li>
              )}
              {state?.showSettings && (
                <li
                  className="nav-item"
                  onClick={() => {
                    onCloseSidebar()
                    const func = () => {
                      props.history.push("/admin/settings");
                    };
                    if (Object.keys(newSurveyData)?.length > 0) {
                      setState({
                        ...state,
                        showRouteChangePopUp: true,
                        callBack: func,
                      });
                    } else {
                      func();
                    }
                  }}
                >
                  <Link
                    to=""
                    onClick={(e) => e.preventDefault()}
                    className={
                      window.location.href.includes("settings")
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="settings-icon"></i>
                    Settings
                  </Link>
                </li>
              )}

              <li style={{ overflow: "auto" }}>
                <Link
                  to=""
                  className="nav-link link-dark"
                  data-bs-toggle="modal"
                  data-bs-target="#logoutModal"
                >
                  <i className="logout-icon"></i>
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <ConfirmationModel
          id={"logoutModal"}
          action={handleLogout}
          msg={"Are you sure you want to log out?"}
          actionText={"Yes"}
          cancelTxt={"Cancel"}
        />
        <ConfirmationModel
          show={state?.showRouteChangePopUp}
          action={() => {
            setState({
              ...state,
              showRouteChangePopUp: false,
            });
            state?.callBack();
            dispatch({
              type: NEW_SURVEY_DATA,
              payload: {},
            });
          }}
          msg={
            "There is a survey in draft state,you will lose progress.Do you want to continue?"
          }
          actionText={"Yes"}
          cancelTxt={"Cancel"}
          secondaryAction={() => {
            setState({
              ...state,
              showRouteChangePopUp: false,
            });
          }}
        />
      </div>
    </Navbar>
  );
};

export default SidebarNew;
