import {
  GET_SURVEY,
  GET_SURVEY_DETAILS,
  GET_QUEST_SECTION,
  GET_EXCEL_FILE,
  SET_FIELD_LOADER,
  ERROR_MSG,
  GET_QUEST_DETAILS,
  UPDATE_MENTION_CONTEXT,
  GET_SURVEY_LIST,
  GET_ACTIVE_LANG_TAB,
  SURVEY_DESC_OF_WHICH_QUES,
  GET_IDENTIFIER_OF_WHICH_QUES,
  GET_ALL_SECTIONS,
  GET_ALL_QUESTIONS,
  EMPTY_FIELD_ERROR,
  UNIQUE_IDENTIFIER_ERROR,
  NEW_SURVEY_DATA,
  RESET_STATE
} from "../actionTypes/survey_action_type";
import { PROFILE_DATA } from "../actionTypes/keppel_action_type";

const initialState = {
  surveys: [],
  surveyDetails: {},
  surveyQuest: [],
  surveyExcel: null,
  nameLoader: false,
  apiMsg: "",
  userProfile: {},
  questionData: 0,
  mentionContext: [],
  surveyList: [],
  activeLangTab: 0,
  showDescOfWhichQues: {},
  uniqueIdenOfWhichQues: {},
  allSections: [],
  allQuestions: [],
  emptyFieldErrArray:[],
  uniqueIdenErrArray:[],
  newSurveyData:{}
};

const survey_reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_STATE:
      return initialState
    case GET_SURVEY:
      return {
        ...state,
        surveys: (action.payload && action.payload.record) || [],
      };
    case GET_SURVEY_LIST:
      return {
        ...state,
        surveyList: (action.payload && action.payload.record) || [],
      };
    case GET_SURVEY_DETAILS:
      return {
        ...state,
        surveyDetails: action && action.payload,
      };
    case GET_QUEST_SECTION:
      return {
        ...state,
        surveyQuest: action && action.payload,
      };
    case GET_EXCEL_FILE:
      return {
        ...state,
        surveyExcel: action && action.payload,
      };
    case GET_QUEST_DETAILS:
      return {
        ...state,
        questionData: action && action.payload,
      };
    case UPDATE_MENTION_CONTEXT:
      return {
        ...state,
        mentionContext: action && action.payload,
      };
    case SET_FIELD_LOADER: {
      return {
        ...state,
        nameLoader: action.payload,
      };
    }
    case ERROR_MSG: {
      return {
        ...state,
        apiMsg: action && action.payload,
      };
    }
    case PROFILE_DATA: {
      return {
        ...state,
        userProfile: action && action.payload,
      };
    }
    case GET_ACTIVE_LANG_TAB: {
      return {
        ...state,
        activeLangTab: action && action.payload,
      };
    }
    case SURVEY_DESC_OF_WHICH_QUES: {
      return {
        ...state,
        showDescOfWhichQues: action && action.payload,
      };
    }
    case GET_IDENTIFIER_OF_WHICH_QUES: {
      return {
        ...state,
        uniqueIdenOfWhichQues: action && action.payload,
      };
    }
    case GET_ALL_SECTIONS: {
      return {
        ...state,
        allSections: action && action.payload,
      };
    }
    case GET_ALL_QUESTIONS: {
      return {
        ...state,
        allQuestions: action && action.payload,
      };
    }
    case EMPTY_FIELD_ERROR: {
      return {
        ...state,
        emptyFieldErrArray: action && action.payload,
      };
    }
    case UNIQUE_IDENTIFIER_ERROR: {
      return {
        ...state,
        uniqueIdenErrArray: action && action.payload,
      };
    }
    case NEW_SURVEY_DATA: {
      return {
        ...state,
        newSurveyData: action && action.payload,
      };
    }
    default:
      return { ...state };
  }
};

export default survey_reducer;
