export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const alphaRegEx = /^[a-zA-Z ]+$/;
export const passwordRegex = /^(?=.{8,32})((?=.*[^a-zA-Z\s])(?=.*[a-z])(?=.*[A-Z])|(?=.*[^a-zA-Z0-9\s])(?=.*\d)(?=.*[a-zA-Z])).*$/;
export const numberRegex = /^[0-9\b]+$/;

export const fraudStatusArray = [
  { key: "Confirmed Fraud", value: "Confirmed-Fraud", color: "red" },
  { key: "Suspected Fraud", value: "Suspected-Fraud", color: "orange" },
  { key: "Genuine Response", value: "Genuine-Response", color: "green" },
  { key: "Pending", value: "Pending", color: "grey" },
];

export const languages = [
  { key: "English", value: "EN" },
  { key: "Hindi", value: "HI" },
  { key: "Bengali", value: "BN" },
  { key: "Tamil", value: "TA" },
  { key: "Chinese", value: "ZH" },
];

export const questionTypes = [
  { key: "CHECK_BOX", value: "Checkbox", Img: "checkbox" },
  { key: "RADIO", value: "Radio Button", Img: "radio" },
  { key: "RADIO_TEXT", value: "Radio with text", Img: "radio" },
  { key: "DROPDOWN", value: "Dropdown", Img: "dropdown" },
  { key: "GRID_RADIO", value: "Radio Button Grid", Img: "radio" },
  { key: "GRID_CHECK", value: "Checkbox Grid", Img: "checkbox" },
  { key: "TEXT", value: "Text Box", Img: "textbox" },
  { key: "IMAGE_UPLOAD", value: "Image", Img: "imgIconBlack" },
  { key: "PASSWORD", value: "Password text", Img: "password" },
  { key: "FILL_BLANKS", value: "Number", Img: "number" },
  { key: "DATE", value: "Date", Img: "date" },
  { key: "DOB", value: "DOB", Img: "dob" },
  { key: "TIME", value: "Time", Img: "clock" },
  { key: "RANGE", value: "Range", Img: "range" },
  { key: "NPS", value: "NPS", Img: "rating" },
  { key: "LOCATION_SEARCH", value: "Location Search", Img: "location" },
  { key: "RATING", value: "Rating", Img: "rating" },
  { key: "TRIP_OVERVIEW", value: "Static", Img: "stat" },
  { key: "CSAT", value: "CSAT", Img: "csat" },
  { key: "SIGNATURE", value: "Signature", Img: "sign" },
];
