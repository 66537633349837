import {GET_INCENTIVES, UPDATE_INCENTIVE_QUERY, GET_INCENTIVE, EXPORT_INCENTIVE_DATA} 
    from '../actionTypes/incentive_action_type';

const initialState = {
    incentives: [],
    incentiveCount: 0,
    incentiveQuery: {
        page: 1,
        limit: 30,
        search: undefined
    },
    incentiveDetail:{},
}

const incentive_reducer = (state = initialState, action = {}) => {
switch (action.type) {
    case GET_INCENTIVES:
        return { ...state, incentives: action.payload.incentive, incentiveCount: action.payload.count }
    case UPDATE_INCENTIVE_QUERY:
        return { ...state, incentiveQuery: { ...action.payload } }
    case GET_INCENTIVE:{
        return {...state, incentiveDetail:{...action.payload}}
    }
    case EXPORT_INCENTIVE_DATA:{
        return {...state, responseData:{...action.payload}}
    }
    default:
        return { ...state }
    }
}

export default incentive_reducer
