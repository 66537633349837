import {GET_CONTACTS} from "../actionTypes/contacts_action_types";
import { CONTACTS_QUERY } from "../actionTypes/contacts_action_types";

const initialState = {
contactsList:[],
contactsQuery:{
limit:30,
page:0,
search:""
}
};

const contact_reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CONTACTS:
      return { ...state, contactsList: action.payload };
    case CONTACTS_QUERY:
        return { ...state, contactsQuery: action.payload };
   
    default:
      return { ...state };
  }
};

export default contact_reducer;
