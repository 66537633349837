import Pages from "../layouts/Pages";
import Dashboard from "../layouts/Dashboard";

export const indexRoutes = [
  { path: "/admin", name: "Home", component: Dashboard }
];

export const mainRoutes= [
  { path: "/", name: "Pages", component: Pages },
]
