import SET_LOADER from '../actionTypes/SET_LOADER';

const initialState = {
  isLoading:false
}

const loader_reducer = (state = initialState, action = {}) => {
  if (action.type === SET_LOADER.LOADING) {
      return {
        ...state,
        isLoading:action.payload 
      }
    }
    else{
      return { ...state }
    }      
}

export default loader_reducer
