export const APPOINTMENT_lIST = 'apppointment-list';
export const UPDATE_QUERY = 'update-appointment-list';
export const APPOINTMENT_BOOKING_FIELDS = 'appointment-booking-fields';
export const REQUEST_CALLBACK_LIST = 'request-callback-list';
export const UPDATE_CALLBACK_QUERY = 'update-callback-query';
export const RESET_INITIAL_DATA = 'reset_initial_data';
export const AGENT_LIST = 'agent-list';
export const UPDATE_AGENT_QUERY = 'update-agent-list';
export const SLOT_DATA = 'slot-data';
export const UPDATE_SLOT_DATE = 'update-slot-date';
export const UPDATE_COPY_DATE = 'update-copy-date';
export const UPDATE_SLOT_AGENT_COUNT = "UPDATE_SLOT_AGENT_COUNT";
export const TOGGLE_ALL_APPOINTMENTS = "TOGGLE_ALL_APPOINTMENTS";
export const SET_ADMIN_ROLES = "SET_ADMIN_ROLES";
export const SET_MODULE_ROLES = "SET_MODULE_ROLES";
export const SET_MODULE_NAMES = 'SET_MODULE_NAMES';
export const PROFILE_DATA = 'PROFILE_DATA;'
export const INTERVIEWER_lIST = 'INTERVIEWER_lIST'
export const AUDIENCE_lIST = 'AUDIENCE_lIST'
export const STOP_SCROLL = "STOP_SCROLL"