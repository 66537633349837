export const GET_SURVEY = "GET_SURVEY";
export const GET_SURVEY_DETAILS = "GET_SURVEY_DETAILS";
export const GET_QUEST_SECTION = 'GET_QUEST_SECTION';
export const GET_QUEST_DETAILS = 'GET_QUEST_DETAILS';
export const UPDATE_MENTION_CONTEXT = 'UPDATE_MENTION_CONTEXT';
export const SET_FIELD_LOADER = 'SET_FIELD_LOADER';
export const ERROR_MSG = 'ERROR_MSG';
export const GET_SURVEY_LIST = 'GET_SURVEY_LIST';
export const GET_ACTIVE_LANG_TAB = 'GET_ACTIVE_LANG_TAB';
export const SURVEY_DESC_OF_WHICH_QUES='SURVEY_DESC_OF_WHICH_QUES';
export const GET_IDENTIFIER_OF_WHICH_QUES='GET_IDENTIFIER_OF_WHICH_QUES';
export const GET_ALL_SECTIONS='GET_ALL_SECTIONS';
export const GET_ALL_QUESTIONS='GET_ALL_QUESTIONS';
export const EMPTY_FIELD_ERROR='EMPTY_FIELD_ERROR';
export const UNIQUE_IDENTIFIER_ERROR='UNIQUE_IDENTIFIER_ERROR';
export const NEW_SURVEY_DATA='NEW_SURVEY_DATA';
export const GET_EXCEL_FILE='GET_EXCEL_FILE';
export const RESET_STATE='RESET_STATE';
