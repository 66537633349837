export const MODULES = {
  USERS: 1,
  AUDIT: 2,
  SURVEY: 3,
  RESPONSES: 4,
  ANALYTICS: 5,
  INCENTIVES: 6,
  // INC_AMOUNT:7,
  SETTINGS: 8,
  INTERVIEWERS: 10,
  AUDIENCE: 11,
};

export const ROLES = {
  None: 0,
  Viewer: 4,
  Editor: 5,
  // ViewerPro:4,
  // EditorPro:5,
  // Owner:3
};

export const MODULE_PATH = {
  1: "/admin/users",
  2: "/admin/auditlogs",
  3: "/admin/addsurvey",
  4: "/admin/editsurvey",
  5: "/admin/responses",
  6: "/admin/analytics",
  7: "/admin/incentives",
};

export const SESSION_TIMEOUT = [
  { label: "1 minute", value: 60 },
  { label: "15 minutes", value: 900 },
  { label: "30 minutes", value: 1800 },
  { label: "1 hour", value: 3600 },
  { label: "8 hours", value: 28800 },
  { label: "24 hours", value: 86400 },
];

export const EMAIL_TEMPLATE = [
  { type: "ADMIN_WELCOME_MAIL", name: "New sub-admin registration" },
  { type: "EMAIL_OTP", name: "Admin login OTP" },
  { type: "FORGOT_PASSWORD", name: "Reset password" },
  { type: "PERMISSION_MODIFICATION", name: "Admin permission modification" },
  { type: "RESPONSE_QUE_ANS", name: "Response submitted by respondent" },
  { type: "NOTIFY_AUDIENCE", name: "Notify audience" },
  { type: "NOTIFY_INTERVIEWER", name: "Notify interviewer" },
  { type: "RESET_INTERVIEWER_CODE", name: "Reset interviewer code" },
];
