import { combineReducers } from 'redux';
import admin_reducer from './admin_reducer';
import adminError from './admin_error_reducer';
import loader_reducer from './loader_reducer';
import keppel_reducer from './keppel_reducer';
import survey_reducer from './survey_reducer';
import response_reducer from './response_reducer'
import incentive_reducer from './incentive_reducer'
import audit_reducer from './audit_reducer';
import contact_reducer from './contact_reducer';
 
export default combineReducers({
  admin_reducer,
  adminError,
  loader_reducer,
  keppel_reducer,
  survey_reducer,
  response_reducer,
  incentive_reducer,
  audit_reducer,
  contact_reducer
});
