import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Snack from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
// @mui/icons-material
import Close from "@mui/icons-material/Close";

import snackbarContentStyle from "assets/jss/material-dashboard-pro-react/components/snackbarContentStyle.jsx";

function Snackbar({ ...props }) {
  const { classes, message, color, close, icon, place, open, type } = props;
  var action = [];
  const messageClasses = cx({
    // [classes.iconMessage]: icon !== undefined
  });
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => props.closeNotification()}
      >
        <Close
          className={classes.close}
          style={{ width: "20px", height: "20px"}}
        />
      </IconButton>,
    ];
  }
  const iconClasses = cx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === "info",
    [classes.successIcon]: color === "success",
    [classes.warningIcon]: color === "warning",
    [classes.dangerIcon]: color === "danger",
    [classes.primaryIcon]: color === "primary",
    [classes.roseIcon]: color === "rose",
  });
  return (
    <Snack
      classes={{
        anchorOriginTopCenter: classes.top20,
        anchorOriginTopRight: classes.top40,
        anchorOriginTopLeft: classes.top40,
      }}
      anchorOrigin={{
        vertical: place.indexOf("t") === -1 ? "bottom" : "top",
        horizontal:
          place.indexOf("l") !== -1
            ? "left"
            : place.indexOf("c") !== -1
              ? "center"
              : "right",
      }}
      open={open}
      message={
        <div>
          {type == "success" ? (
            <div
              style={{
                borderLeft: "4px solid #51c479",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                
              }}
            >
              <div
                className={messageClasses}
                style={{ paddingTop: "16px", paddingBottom: "16px" }}
              >
                {icon !== undefined ? (
                  <props.icon style={{ color: "#51c479" }} />
                ) : null}
                {message}
              </div>
            </div>
          ) : (
            <div
              style={{
                borderLeft: "4px solid #f43b63",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                float: "left",
                
              }}
            >
              <div
                className={messageClasses}
                style={{ paddingTop: "16px", paddingBottom: "16px" }}
              >
                <div style={{
                  float: 'left',
                  width: '35px'
                }}>{icon !== undefined ? (
                  <props.icon style={{ color: "#fff"}} />
                ) : null}</div>
                <div style={{
                  width: 'calc(100% - 35px)',
                  float: 'left',
                  paddingBottom: '16px',
                  
                }}>
                  {message}
                </div>
              </div>
            </div>
          )}
        </div>
      }
      action={action}
      ContentProps={{
        classes: {
          root: classes.root + " " + classes[color],
          message: classes.message,
        },
      }}
    />
  );
}

Snackbar.defaultProps = {
  color: "info",
};

Snackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "info",
    "success",
    "warning",
    "danger",
    "primary",
    "rose",
  ]),
  close: PropTypes.bool,
  icon: PropTypes.func,
  place: PropTypes.oneOf(["tl", "tr", "tc", "br", "bl", "bc"]),
  open: PropTypes.bool,
};

export default withStyles(snackbarContentStyle)(Snackbar);
