import { axiosInstnace as axios, domain } from "../utils/util";
import SET_LOADER from "../actionTypes/SET_LOADER";
import {
  GET_AUDITLOGS,
  UPDATE_AUDIT_QUERY,
  GET_AUDIT_DETAIL,
  EXPORT_AUDIT_DATA,
  GET_AUDIT_FILTERS,
} from "../actionTypes/audit_action_type";
import qs from "qs";
import moment from "moment-timezone";
import ADMIN_ACTIONS from "../actionTypes/ADMIN_ACTIONS";
import { date } from "is_js";

export const getAuditLogs = (userProfile, auditQuery) => async (dispatch) => {
  const query = auditQuery || {
    limit: 30,
    page: 1,
    search: null,
    status: undefined,
    dateFrom: "",
    dateTo: "",
    action: "",
    dataTypes: "",
  };
  dispatch(updateAuditQueries(query));
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  let searchVal =
    (query && query.search && query.search.replace("+", "%2B")) || null;
  let actionVal = (query && query.action) || null;
  let datatTypeVal = (query && query.dataTypes) || null;

  let is_superAdmin = userProfile && userProfile.is_superadmin;
  let organization_id =
    userProfile && userProfile.organization && userProfile.organization.id;
  let queryParams = null;
  let dateTo = new Date(query?.dateTo);
  dateTo.setDate(dateTo.getDate() + 1);
  if (is_superAdmin === "super_admin") {
    queryParams = qs.stringify(
      {
        limit: query.limit,
        page: query.page,
        searchVal: searchVal ? searchVal : null,
        action: actionVal ? actionVal : null,
        dataTypes: datatTypeVal ? datatTypeVal : null,
        dateFrom:
          query && query.dateFrom && query.dateTo ? query.dateFrom : null,
        dateTo: query && query.dateFrom && query.dateTo ? dateTo : null,
      },
      { skipNulls: true }
    );
  } else {
    queryParams = qs.stringify(
      {
        organization_id: organization_id,
        limit: query.limit,
        page: query.page,
        searchVal: searchVal ? searchVal : null,
        action: actionVal ? actionVal : null,
        dataTypes: datatTypeVal ? datatTypeVal : null,
        dateFrom:
          query && query.dateFrom && query.dateTo ? query.dateFrom : null,
        dateTo: query && query.dateFrom && query.dateTo ? dateTo : null,
      },
      { skipNulls: true }
    );
  }
  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}audit/list?${queryParams}`)
      .then((response) => {
        let data = response?.data?.data?.record?.map((item) => {
          return {
            date: moment(item.created_at)
              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .format("DD/MM/YYYY"),
            time: moment(item.created_at)
              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .format("hh:mm a"),
            ...item,
          };
        });

        const groupedByDate = data.reduce((acc, item) => {
          if (!acc[item.date]) {
            acc[item.date] = [];
          }

          acc[item.date].push({
            time: item.time,
            event: item.event_title,
            description: item.description,
          });
          return acc;
        }, {});

        const result = Object.keys(groupedByDate).map((date) => {
          return {
            date: date,
            actions: groupedByDate[date],
          };
        });

        dispatch({
          type: GET_AUDITLOGS,
          payload: {
            auditLogs: result,
            count: response && response.data && response.data.count,
          },
        });

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const updateAuditQueries = (query) => ({
  type: UPDATE_AUDIT_QUERY,
  payload: query,
});

export const getAuditDetails = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });
  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}audit/details/${id}`)
      .then((response) => {
        dispatch({
          type: GET_AUDIT_DETAIL,
          payload: {
            response: response && response.data && response.data.data,
          },
        });

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getAuditFilters = () => async (dispatch) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}audit/filters`)
      .then((response) => {
        dispatch({
          type: GET_AUDIT_FILTERS,
          payload: {
            response: response && response.data && response.data.data,
          },
        });
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      })
  );
};

export const exportAuditLogs = (auditQuery, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });
  let payLoadQuery = JSON.parse(JSON.stringify(auditQuery));
  if (!payLoadQuery.dateFrom && !payLoadQuery.dateTo) {
    let date = new Date();
    let oldMonth = date.getMonth() - 1;
    date.setMonth(oldMonth);

    payLoadQuery.dateFrom = date;
    payLoadQuery.dateTo = new Date();
  }
  const query = payLoadQuery || {
    limit: 30,
    page: 1,
    search: null,
    dateFrom: null,
    dateTo: null,
    action: null,
    dataTypes: null,
  };

  // dispatch(updateAuditQueries(query));

  let searchVal =
    (query && query.search && query.search.replace("+", "%2B")) || null;
  let actionVal = (query && query.action) || null;
  let datatTypeVal = (query && query.dataTypes) || null;
  let queryParams = qs.stringify(
    {
      searchVal: searchVal ? searchVal : null,
      action: actionVal ? actionVal : null,
      dataTypes: datatTypeVal ? datatTypeVal : null,
      dateFrom: query && query.dateFrom && query.dateTo ? query.dateFrom : null,
      dateTo: query && query.dateFrom && query.dateTo ? query.dateTo : null,
    },
    { skipNulls: true }
  );
  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}audit/export-auditlog?${queryParams}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (callback) {
          callback();
        }
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        dispatch({
          type: EXPORT_AUDIT_DATA,
          payload: { data: response && response.data && response.data.data },
        });
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};
