import {
  axiosInstnace as axios,
  axios4,
  domain,
  getData,
  global_axios,
  global_domain,
} from "../utils/util";
import SET_LOADER from "../actionTypes/SET_LOADER";
import {
  GET_USER,
  GET_USERS,
  UPDATE_USER_QUERY,
  EXPORT_DATA,
  GET_UPDATES,
} from "../actionTypes/user_action_type";
import {
  SET_MODULE_ROLES,
  SET_ADMIN_ROLES,
  SET_MODULE_NAMES,
  PROFILE_DATA,
} from "../actionTypes/keppel_action_type";
import { MODULE_PATH } from "../constants/admin";
import { adminErrors } from "../constants/errors";
import { toast } from "react-toastify";
import { setData } from "../utils/util";
import ADMIN_ACTIONS from "../actionTypes/ADMIN_ACTIONS";

export const getUsers = (userQuery) => async (dispatch) => {
  const query = userQuery || {
    limit: 30,
    page: 0,
    startdate: "",
    enddate: "",
    search: "",
  };

  dispatch(updateUserQueries(query));

  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .get(
        `${domain}user/list?limit=${query.limit}&page=${query.page}&dateFrom=${query.startdate}&dateTo=${query.enddate}&search=${query.search}`
      )
      .then((response) => {
        dispatch({
          type: GET_USERS,
          payload: {
            user: response && response.data && response.data.data,
            count: response && response.data && response.data.count,
          },
        });

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const updateUserQueries = (query) => ({
  type: UPDATE_USER_QUERY,
  payload: query,
});

export const createAdmin = (user, hist) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING , payload:true});

  axios
    .post(`${domain}/api/createAdmin`, user)
    .then(({ data }) => {
      if (data.success) {
        hist.push("/admin/users");
        toast.success("User created successfully!");
      } else {
        toast.error(data.message);
      }
    })
    .catch((err) => {
      toast.error(adminErrors.apiFailure);
    })
    .finally(() => {
      dispatch({ type: SET_LOADER.LOADING, payload: false });
      dispatch(getUsers());
    });
};

export const removeUser = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING , payload:true});

  axios
    .delete(`${domain}user/${id}`)
    .then(() => {
      toast.success("User deleted successfully!");
    })
    .catch((err) => {
      dispatch({ type: SET_LOADER.LOADING, payload: false });
    })
    .finally(() => {
      dispatch({ type: SET_LOADER.LOADING, payload: false });
      dispatch(getUsers());
    });
};

export const updateAdmin = (user, hist) => async (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING , payload:true});

  axios
    .post(`${domain}/api/editAdmin`, user)
    .then(({ data }) => {
      if (data.success) {
        toast.success("User updated successfully!");
        hist.push("/admin/users");
      } else {
        toast.error(data.message);
      }
    })
    .catch((err) => {
      toast.error(adminErrors.apiFailure);
    })
    .finally(() => {
      dispatch({ type: SET_LOADER.LOADING, payload: false });
      dispatch(getUsers());
    });
};

export const toggleUser = (id, value) => async (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING , payload:true});

  axios
    .post(`${domain}/api/blockAdmin/${id}`, { isblock: value })
    .then(() => {
      toast.success("User updated successfully!");
    })
    .catch((err) => {
      toast.error(adminErrors.apiFailure);
    })
    .finally(() => {
      dispatch({ type: SET_LOADER.LOADING, payload: false });
      dispatch(getUsers());
    });
};

export const getUser = (id, callback) => async (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });
  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}user/${id}`)
      .then((response) => {
        if (callback) {
          callback();
        }
        dispatch({
          type: GET_USER,
          payload: { user: response && response.data && response.data.data },
        });

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const updateUser = (payload, callback, history) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });
  return new Promise((resolve, reject) =>
    axios
      .put(`${domain}user`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
      .finally(() => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        dispatch(getUsers());
      })
  );
};

export const addUser = (payload, callback, history) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });
  return new Promise((resolve, reject) =>
    axios
      .post(`${domain}user`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getModuleRoles = (callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });
  return new Promise((resolve, reject) =>
    global_axios
      .get(`${global_domain}v1/admin/user/module-role-permissions`)
      .then((response) => {
        if (callback) {
          callback();
        }
        const moduleRoles = {};
        const moduleNames = {};
        response &&
          response.data &&
          response.data.data.forEach((module) => {
            const roles = {};
            module.roles.forEach((role) => {
              roles[role.id] = role;
            });
            moduleRoles[module.module_id] = roles;
            moduleNames[module.module_id] = module.name;
          });

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        dispatch({
          type: SET_MODULE_ROLES,
          payload: moduleRoles,
        });
        dispatch({
          type: SET_MODULE_NAMES,
          payload: moduleNames,
        });
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getRoles = (callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });
  return new Promise((resolve, reject) =>
    global_axios
      .get(`${global_domain}v1/admin/user/role-list`)
      .then((response) => {
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const editOrganizationName = (payload, callback) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });
  axios
    .put(`${domain}organization`, payload)
    .then((res) => {
      if (res) {
        axios.get(`${domain}user/profile`).then((resp) => {
          if (resp) {
            callback(resp.data.data.organization.name);
            setData("userProfile", resp.data);
          }
          dispatch({
            type: SET_LOADER.LOADING,
            payload: false,
          });
          toast.success(res.data.message);
        });
      }
    })
    .catch(() => {
      dispatch({
        type: SET_LOADER.LOADING,
        payload: false,
      });
    });
};

export const fetchUserModuleRole = (isInit, loginMessage, history) => async (
  dispatch
) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });
  if (isInit === true) {
    axios.get(`${domain}user/profile`).then((res) => {
      if (res) {
        const { data = {} } = res;
        if (data && data.data) {
          data.data.is_superadmin =
            data.data.is_superadmin == "sub_admin"
              ? ""
              : data.data.is_superadmin;
        }
        setData("userProfile", data);
        return new Promise((resolve, reject) =>
          global_axios
            .get(`${global_domain}v1/admin/user/user-permissions`)
            .then((resp) => {
              if (resp) {
                const response = resp.data && resp.data.data;
                const rolesData = {};
                // console.log(isSuperAdmin,"issuperAdmin")
                if (data && !data.data.is_superadmin) {
                  if (response) {
                    if (response.length === 0) {
                      toast.error(adminErrors.accessBlocked);
                      sessionStorage.removeItem("adminData");
                      history.push("/");
                    } else {
                      sessionStorage.removeItem("adminData");
                      response.forEach((role) => {
                        rolesData[role.module_id] = role;
                      });

                      setData("rolesData", response);
                      setData("adminRoles", rolesData);
                      if (loginMessage) {
                        toast.success(loginMessage);
                      }
                      history.push("/admin/surveys");
                      // history.push("/admin/analytics");
                      // if (MODULE_PATH[Object.keys(rolesData)[0]] === "/audit") {
                      //   if (
                      //     MODULE_PATH[Object.keys(rolesData)[1]] === "/addsurvey"
                      //   ) {
                      //     history.push("/addsurvey");
                      //   } else {
                      //     history.push(MODULE_PATH[Object.keys(rolesData)[1]]);
                      //   }
                      // } else if (
                      //   MODULE_PATH[Object.keys(rolesData)[0]] === "/addsurvey"
                      // ) {
                      //   history.push("/addsurvey");
                      // } else {
                      //   history.push(MODULE_PATH[Object.keys(rolesData)[0]]);
                      // }

                      dispatch({
                        type: SET_ADMIN_ROLES,
                        payload: rolesData,
                      });
                    }
                  }
                } else {
                  setData("rolesData", response);
                  setData("adminRoles", rolesData);
                  if (isInit) {
                    // history.push({ state: "login", pathname: "/users" });
                    // history.push("/admin/analytics");
                    history.push("/admin/surveys");
                  }
                  if (loginMessage) {
                    toast.success(loginMessage);
                  }
                }
                dispatch({
                  type: SET_LOADER.LOADING,
                  payload: false,
                });
                /*setTimeout(() => {
                  dispatch({
                    type: SET_LOADER.LOADING,
                    payload: false
                  });
                  sessionStorage.removeItem("adminData");
                }, 3000)*/
                return resolve(resp);
              }
            })
        );
      }
    });
  } else {
    const { data = {} } = getData("userProfile");
    return new Promise((resolve, reject) =>
      global_axios
        .get(`${global_domain}v1/admin/user/user-permissions`)
        .then((resp) => {
          if (resp) {
            const response = resp.data && resp.data.data;
            const rolesData = {};
            // console.log(isSuperAdmin,"issuperAdmin")
            if (data && !data.is_superadmin) {
              if (response) {
                if (response.length === 0) {
                  // toast.error(adminErrors.accessBlocked);
                  sessionStorage.removeItem("adminData");
                  history.push("/");
                } else {
                  sessionStorage.removeItem("adminData");
                  response.forEach((role) => {
                    rolesData[role.module_id] = role;
                  });
                  setData("rolesData", response);
                  setData("adminRoles", rolesData);
                  if (loginMessage) {
                    toast.success(loginMessage);
                  }

                  dispatch({
                    type: SET_ADMIN_ROLES,
                    payload: rolesData,
                  });
                }
              }
            } else {
              setData("rolesData", response);
              setData("adminRoles", rolesData);
              if (isInit) {
                //  history.push({ state: "login", pathname: "/users" });
                // history.push("/admin/analytics");
                history.push("/admin/surveys");
              }
              if (loginMessage) {
                toast.success(loginMessage);
              }
            }
            dispatch({
              type: SET_LOADER.LOADING,
              payload: false,
            });
            // setTimeout(() => {
            //         dispatch({
            //           type: SET_LOADER.LOADING,
            //           payload: false
            //         });
            //         sessionStorage.removeItem("adminData");
            //       }, 3000)
            return resolve(resp);
          }
        })
    );
  }
};

export const exportUserData = (payload, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${domain}user/exportUser?search=${payload.search}&dateFrom=${payload.startdate}&dateTo=${payload.enddate}`
      )
      .then((response) => {
        if (callback) {
          callback();
        }
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        dispatch({
          type: EXPORT_DATA,
          payload: { data: response && response.data && response.data.data },
        });
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getUserProfile = (callback) => async (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });
  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}user/profile`)
      .then((response) => {
        if (callback) {
          callback();
        }
        // setTimeout(() => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        //}, 4000)

        dispatch({ type: PROFILE_DATA, payload: response.data.data });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getUpdates = () => async (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}platform`)
      .then((response) => {
        dispatch({
          type: GET_UPDATES,
          payload: response.data.data,
        });

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const deleteUser = (id, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios4
      .delete(`${domain}common/user-records/${id}`)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};
