import {
  axiosInstnace as axios,
  domain,
  setData,
  deleteUserData,
  removeData,
  getData,
  axiosObj,
  axiosPreLogin,
  axiosInstnace,
} from "../utils/util";
import ADMIN_ACTIONS from "../actionTypes/ADMIN_ACTIONS";
import SET_LOADER from "../actionTypes/SET_LOADER";
import { fetchUserModuleRole, getUserProfile } from "./user_action";
import qs from "qs";
import moment from "moment";
import { toast } from "react-toastify";
import { RESET_STATE } from "actionTypes/survey_action_type";

export const login = (user, history) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });

  return new Promise((resolve, reject) =>
    axiosPreLogin
      .post(`${domain}user/auth/signin`, user, { handlerEnabled: false })
      .then((response) => {
        setData("loginData", response.data.data);
        // dispatch(getRolesAndAppointmentData(true, response.data.msg, history))

        dispatch({
          type: ADMIN_ACTIONS.LOGIN,
          payload: response.data,
        });
        if (response.data.data.two_factor_auth) {
          history.push({ state: "login", pathname: "/codeVerification" });
        } else {
          let date = new Date()
          setData("adminData", response.data && response.data.data);
          const tokenRes = (response.data && response.data.data) || {};
          setData("loggedUser", tokenRes && tokenRes.token);
          setData("iframeURL", tokenRes && tokenRes.iframeUrl);
          setData("newSession",date.getTime())
          removeData("loginData");
          dispatch({
            type: ADMIN_ACTIONS.LOGIN,
            payload: response.data,
          });
          dispatch(fetchUserModuleRole(true, response.data.message, history));
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: ADMIN_ACTIONS.LOGIN_ERROR,
          payload: err.response.data.message,
        });
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};
export const loginByGoogle = (user, history) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });

  return new Promise((resolve, reject) =>
    axiosPreLogin
      .post(`${domain}user/auth/google-signin-signup`, user, { handlerEnabled: false })
      .then((response) => {
        setData("loginData", response.data.data);
        // dispatch(getRolesAndAppointmentData(true, response.data.msg, history))

        dispatch({
          type: ADMIN_ACTIONS.LOGIN,
          payload: response.data,
        });
        if (response.data.data.two_factor_auth) {
          history.push({ state: "login", pathname: "/codeVerification" });
        } else {
          setData("adminData", response.data && response.data.data);
          const tokenRes = (response.data && response.data.data) || {};
          setData("loggedUser", tokenRes && tokenRes.token);
          setData("iframeURL", tokenRes && tokenRes.iframeUrl);
          removeData("loginData");
          dispatch({
            type: ADMIN_ACTIONS.LOGIN,
            payload: response.data,
          });
          dispatch(fetchUserModuleRole(true, response.data.message, history));
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        if (err.response.data.errorCode === "LTA-E0069") {
          toast.error(
            "This email is not registered, please select a plan to sign up"
          );
          history.push("/selectPlan");
        } else {
          dispatch({
            type: ADMIN_ACTIONS.LOGIN_ERROR,
            payload: err.response.data.message,
          });
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const contactUs = (data, callback) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });

  return new Promise((resolve, reject) =>
    axios
      .post(`${domain}user/contact-us`, data)
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const register = (user, history) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });

  return new Promise((resolve, reject) =>
    axiosPreLogin
      .post(`${domain}user/register`, user, { handlerEnabled: false })
      .then((response) => {
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        history.push({
          state: "register",
          pathname: "/registeredSuccessfully",
        });
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: ADMIN_ACTIONS.REGISTER_ERROR,
          payload: err.response.data.message,
        });
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const verification = (data, history) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });

  return new Promise((resolve, reject) =>
    axiosPreLogin
      .post(`${domain}user/auth/signin/otp`, data, { handlerEnabled: false })
      .then((response) => {
        setData("adminData", response.data && response.data.data);
        const tokenRes = (response.data && response.data.data) || {};
        setData("loggedUser", tokenRes && tokenRes.token);
        setData("iframeURL", tokenRes && tokenRes.iframeUrl);
        removeData("loginData");
        dispatch({
          type: ADMIN_ACTIONS.LOGIN,
          payload: response.data,
        });
        dispatch(fetchUserModuleRole(true, response.data.message, history));

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: ADMIN_ACTIONS.CODE_VER_ERROR,
          payload: err.response.data.message,
        });
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const logout = (history) => async (dispatch) => {
  // console.log('logout api')
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });

  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}user/admin-logout`)
      .then((response) => {
        deleteUserData();
        toast.success(response.data.message);
        history.push("/login");
        // dispatch({
        //   type: RESET_STATE,
        // });

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const forgotPassword = (payload, history) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });

  return new Promise((resolve, reject) =>
    axiosPreLogin
      .get(`${domain}user/auth/forget/${payload.email}`, {
        handlerEnabled: false,
      })
      .then((response) => {
        if (response) {
          toast.success(response.data.message);

          dispatch({
            type: SET_LOADER.LOADING,
            payload: false,
          });

          if (response.data && response.data.data && response.data.data.url) {
            const string = response.data.data.url;
            const token = string.substring(string.indexOf("=") + 1);
            history.push(`/codeVerification?token=${token}`);
          }
          return resolve(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: ADMIN_ACTIONS.FORGOT_PASS_ERROR,
          payload: err.response.data.message,
        });
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const resetPassword = (payload, callback) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });
  return new Promise(async (resolve, reject) =>
    axios
      .put(`${domain}user/admin-change-password`, payload)
      .then(async (response) => {
        toast.success(response.data.message);

        setData("adminData", response.data && response.data.data);
        const tokenRes = (response.data && response.data.data) || {};
        setData("loggedUser", tokenRes && tokenRes.token);
        setData("iframeURL", tokenRes && tokenRes.iframeUrl);
        let userData = getData("userProfile");
        userData.data.force_password_change = false;
        setData("userProfile", userData);

        if (callback) {
          callback();
        }
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const getPackages = (callback) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });
  return new Promise(async (resolve, reject) =>
    axiosObj
      .get(`${domain}packages`)
      .then(async (response) => {
        if (callback) {
          await callback(response.data);
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const updateAdminProfile = (payload, callback) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });
  return new Promise((resolve, reject) =>
    axios
      .put(`${domain}user/profile`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const resendOtp = (payload, callback) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });
  return new Promise((resolve, reject) =>
    axios
      .post(`${domain}user/otp/resend`, payload, { handlerEnabled: false })
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const validateOTC = (payload, callback) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });
  return new Promise((resolve, reject) =>
    axiosPreLogin
      .post(`${domain}user/auth/validateOTC`, payload, {
        handlerEnabled: false,
      })
      .then((response) => {
        toast.success(response.data.message);
        // console.log(callback,"callback")
        setData("otcData", response.data.data);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: ADMIN_ACTIONS.CODE_VER_ERROR,
          payload: err.response.data.message,
        });
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const resetPasswordAuth = (payload, callback) => async (dispatch) => {
  dispatch({
    type: SET_LOADER.LOADING,
    payload: true,
  });
  return new Promise((resolve, reject) =>
    axiosPreLogin
      .put(`${domain}user/auth/password`, payload, { handlerEnabled: false })
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: ADMIN_ACTIONS.RESET_PSS_ERROR,
          payload: err.response.data.message,
        });
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });
        return reject(err);
      })
  );
};

export const dailyConsumption = (cb) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });
  let lowerDate = new Date();
  let upperDate = new Date();
  let oldMonth = lowerDate.getMonth() - 1;
  let upperLimit = upperDate.getDate() + 1;
  lowerDate.setMonth(oldMonth);
  upperDate.setDate(upperLimit);
  // let queryParams = qs.stringify({
  //   fromDate: date,
  //   toDate: new Date(),
  // });

  return new Promise((resolve, reject) =>
    axios
      .get(
        `${domain}user/daily-consumption/${moment(lowerDate).format(
          "YYYY-MM-DD"
        )}/${moment(upperDate).format("YYYY-MM-DD")}`
      )
      .then((response) => {
        if (cb) {
          cb(response.data.data);
        }
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        dispatch({
          type: ADMIN_ACTIONS.DAILY_CONSUMPTION,
          payload: response.data.data,
        });
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });

        return reject(err);
      })
  );
};
