import React, { Component } from 'react';

class RestrictedImp extends Component {

  render() {
    return (
      <div className="align-center">
            <h4 className="login-txt">You are not authorised for this request!</h4>
        </div>
    )
  }
}


export default RestrictedImp;