export const adminErrors = {
    usernameRequired: "Username is required.",
    acceptAlphabets:"Only alphabets are accepted. Please enter a valid name.",
    mobileRequired:"Mobile number is required.",
    invalidMobile:"Invalid mobile number.",
    otpRequired: "OTP is required.",
    forcePasswordChange:"Please change your password to proceed.",
    validImageFormat:"Only JPEG, JPG and PNG are allowed.",
    validImageSize:"Image size is too big (max 2mb).",
    logoResolution:"Logo resolution should be 500*180 pixels.",
    validImageType:"File type is not supported. Supported file type: png, svg, jpg, jpeg.",
    validFileSize:"File size must not exceed 1 MB.",
    fileLimit:"Please upload one file only.",
    validExcelType:"File type is not supported. Supported file type: xlsx, xls.",
    serverError:"Internal Server Error.",
    apiFailure:"Something went wrong.",
    noData:"No data found.",
    noResponse:"No response found",
    noRecords:"No records found",
    logoRequired:"Logo is missing. Please upload the logo to continue.",
    surveyfileRequired:'Survey file is missing. Please upload a valid survey file to continue.',
    validSurveyName:"Survey Name should be alpha numeric without space.",
    roleRequired:"Please assign atleast one role.",
    accessBlocked:"Your access has been blocked.",
    year_month:"Please select year and month"
}

export const inputErrors = {
    required:"This field is mandatory!",
    invalidCode:"Invalid code!",
    invalidEmail:"Invalid email!",
    invalidMobile:"Invalid mobile number!",
    passCharLength: "Password must be 8 characters long!",
    maxCharlenthReached:"Maximum character length reached!",
    validPassFormat:"The format of the password is not correct. Please use a valid password!",
    passNotMatched:"Password doesn't match!",
    validName:"Only alphabets are accepted. Please enter a valid name!",
    tnc: "Please agree to terms & conditions to proceed"
}