import {
  People,
  History,
  LibraryAdd,
  SettingsApplications,
} from "@mui/icons-material";
// import UserManagement from "../views/LTA/UserManagement";
// import AddUser from "../views/LTA/UserManagement/AddUser";
// import ViewUser from "../views/LTA/UserManagement/ViewUser";
import { MODULES } from "../constants/admin";
import { lazy } from "react";
import Add from "@mui/icons-material/Add";
// import CreateSurvey from "../views/LTA/SurveyManagement/CreateSurevy";
// import EditSurvey from "../views/LTA/SurveyManagement/EditSurvey";
// import ResponseDetails from "../views/LTA/ResponseMangement/ResponseDetails";
// import Responses from "../views/LTA/ResponseMangement/Responses";
// import AuditLogs from "../views/LTA/AuditLogs/index";
// import LogDetail from "../views/LTA/AuditLogs/LogDetail";
// import IncentiveLogs from "../views/LTA/IncentiveLogs";
// import IncentiveDetail from "../views/LTA/IncentiveLogs/IncentiveDetail";
// import NewAnalytics from "../views/LTA/Analytics/NewAnalytics";
// import Settings from "../views/LTA/Settings/Settings";
// import MoreInfo from "../views/LTA/ResponseMangement/MoreInfo";

const UserManagement = lazy(() => import("../views/LTA/UserManagement"));
const PanelManagement = lazy(() => import("../views/LTA/PanelManagement"));
const AddInterviewer = lazy(() => import("../views/LTA/PanelManagement/AddInterviewer"));
const AddUser = lazy(() => import("../views/LTA/UserManagement/AddUser"));

const Responses = lazy(() =>
  import("../views/LTA/ResponseMangement/Responses")
);
const AuditLogs = lazy(() => import("../views/LTA/AuditLogs/index"));
const LogDetail = lazy(() => import("../views/LTA/AuditLogs/LogDetail"));

const NewAnalytics = lazy(() => import("../views/LTA/Analytics/NewAnalytics"));
const Settings = lazy(() => import("../views/LTA/Settings/Settings"));

const ListTable = lazy(() => import("../components/Table/ListTable"));
const CreateNewSurvey = lazy(() =>
  import("../views/LTA/SurveyManagement/createNewSurvey")
);
const AddAudience = lazy(() =>
import("../views/LTA/SurveyManagement/AddAudience")
);

const contactsList = lazy(() =>
import("../views/LTA/Contacts/contacts")
);


var dashRoutes = [
  // {
  //   path: "/admin/analytics",
  //   name: "Analytics",
  //   hide: true,
  //   component: NewAnalytics,
  //   id: MODULES.ANALYTICS,
  // },
  {
    path: "/admin/users",
    name: "User Management",
    hide: false,
    icon: People,
    component: UserManagement,
    main: true,
    id: MODULES.USERS,
  },
  {
    path: "/admin/interviewers",
    name: "Panel Management",
    hide: false,
    icon: People,
    component: PanelManagement,
    main: true,
    id: MODULES.USERS,
  },
  {
    path: "/admin/addinterviewer",
    name: "Add Interviewer",
    hide: false,
    icon: People,
    component: AddInterviewer,
    main: true,
    id: MODULES.USERS,
  },
  {
    path: "/admin/editinterviewer",
    name: "Edit Interviewer",
    hide: false,
    icon: People,
    component: AddInterviewer,
    main: true,
    id: MODULES.USERS,
  },
  {
    path: "/admin/auditlogs",
    name: "Audit Logs",
    hide: false,
    icon: History,
    component: AuditLogs,
    main: true,
    id: MODULES.AUDIT,
  },

  {
    path: "/admin/adduser",
    name: "Add User",
    hide: true,
    component: AddUser,
    id: MODULES.USERS,
  },
  {
    path: "/admin/edituser",
    name: "Edit User",
    hide: true,
    component: AddUser,
    id: MODULES.USERS,
  },

  {
    path: "/admin/responses",
    name: "Responses",
    hide: true,
    component: Responses,
    id: MODULES.RESPONSES,
  },

  {
    path: "/admin/logdetails",
    name: "Log Details",
    hide: true,
    component: LogDetail,
    id: MODULES.AUDIT,
  },

  {
    path: "/admin/settings",
    name: "Settings",
    hide: false,
    icon: SettingsApplications,
    component: Settings,
    main: true,
    id: MODULES.SETTINGS,
  },
  {
    path: "/admin/surveys",
    name: "Table",
    hide: true,
    component: CreateNewSurvey,
    id: MODULES.USERS,
  },
  {
    path: "/admin/addAudience",
    name: "Table",
    hide: true,
    component: AddAudience,
    id: MODULES.USERS,
  },
  {
    path: "/admin/contacts",
    name: "Table",
    hide: true,
    component: contactsList,
    id: MODULES.USERS,
  }
];
export default dashRoutes;
