import React, { useEffect } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { adminStore } from "./store";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "../src/themeConfig";
import { RenderRoute } from "./Routes";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

const Main = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      console.log = () => {};
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={adminStore}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
            <RenderRoute />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </Provider>
    </MuiThemeProvider>
  );
};
render(<Main />, document.getElementById("root"));
