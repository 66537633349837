import ADMIN_ACTIONS from "../actionTypes/ADMIN_ACTIONS";

const initialState = {
  loginError: "",
  forgotPassErr: "",
  codeVerErr: "",
  resetPassErr: "",
  registerErr: "",
  postLoginError: "",
  postLoginSuccess: "",
  dailyConsumption:null
};

const admin_reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADMIN_ACTIONS.LOGIN_ERROR:
      return { ...state, loginError: action.payload };
    case ADMIN_ACTIONS.POST_LOGIN_ERROR:
      return { ...state, postLoginError: action.payload };
    case ADMIN_ACTIONS.POST_LOGIN_SUCCESS:
      return { ...state, postLoginSuccess: action.payload };
    case ADMIN_ACTIONS.FORGOT_PASS_ERROR:
      return { ...state, forgotPassErr: action.payload };
    case ADMIN_ACTIONS.REGISTER_ERROR:
      return { ...state, registerErr: action.payload };
    case ADMIN_ACTIONS.RESET_PSS_ERROR:
      return { ...state, resetPassErr: action.payload };
    case ADMIN_ACTIONS.CODE_VER_ERROR:
      return { ...state, codeVerErr: action.payload };
      case ADMIN_ACTIONS.DAILY_CONSUMPTION:
        return { ...state, dailyConsumption: action.payload };
    default:
      return { ...state };
  }
};

export default admin_reducer;
