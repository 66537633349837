import {
  axiosInstnace as axios,
  domain,
  domain2,
  domain4,
  axiosObj,
  axios4,
} from "../utils/util";
import SET_LOADER from "../actionTypes/SET_LOADER";
import { toast } from "react-toastify";
import {
  GET_SURVEY,
  GET_SURVEY_DETAILS,
  GET_QUEST_SECTION,
  SET_FIELD_LOADER,
  ERROR_MSG,
  GET_SURVEY_LIST,
} from "../actionTypes/survey_action_type";
import { Buffer } from "buffer";
import ADMIN_ACTIONS from "../actionTypes/ADMIN_ACTIONS";
import { UPDATE_AUDIENCE_QUERY } from "actionTypes/user_action_type";
import { AUDIENCE_lIST } from "actionTypes/keppel_action_type";

export const createNewSurvey = (payload, callback, hist) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .post(`${domain}survey`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const createSurvey = (payload, callback, hist) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axiosObj
      .post(`${domain2}survey/create`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const uploadImgFile = (payload, callback, hist) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axiosObj
      .post(`${domain2}survey/upload`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }
        // setTimeout(()=>{
        //     dispatch({
        //       type: SET_LOADER.LOADING,
        //       payload: false
        //     });
        //   },2000)
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const uploadMultipleImgFile = (payload, callback, hist) => (
  dispatch
) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .post(`${domain}importers/upload-multiple-files`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback(response);
        }
        // setTimeout(()=>{
        //     dispatch({
        //       type: SET_LOADER.LOADING,
        //       payload: false
        //     });
        //   },2000)
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const uploadLanguageExcel = (payload, callback, hist) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .post(`${domain}survey/upload-survey`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getAllSurveys = (org_id, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING , payload:true});

  return new Promise((resolve, reject) =>
    axios
      .get(
        `${domain}survey${
          org_id ? `?organization_id=${org_id}&` : "?"
        }page=1&limit=20`
      )
      .then((response) => {
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        dispatch({
          type: GET_SURVEY,
          payload: response.data && response.data.data,
        });
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getSurveyList = (org_id, callback) => (dispatch) => {
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${domain}survey${
          org_id ? `?organization_id=${org_id}&` : "?"
        }page=1&limit=1000`
      )
      .then((response) => {
        dispatch({
          type: GET_SURVEY_LIST,
          payload: response?.data?.data,
        });
        if (callback) {
          callback(response.data);
        }

        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      })
  );
};

export const getSettings = (callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING , payload:true});

  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}settings`)
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
       // sessionStorage.setItem("sessionExpiryTime",response?.data?.data?.admin_session_expiry_time)
        setTimeout(() => {
          dispatch({
            type: SET_LOADER.LOADING,
            payload: false,
          });
        }, 500);
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getBillings = (id, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING , payload:true});

  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}user/billing-details`)
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
        setTimeout(() => {
          dispatch({
            type: SET_LOADER.LOADING,
            payload: false,
          });
        }, 500);
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getVersionList = (survey_id, callback) => (dispatch) => {
  // dispatch({ type: SET_LOADER.LOADING , payload:true});

  return new Promise((resolve, reject) =>
    axios
      .get(`${domain2}survey/version-list/${survey_id}`)
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
        // setTimeout(() => {
        //   dispatch({
        //     type: SET_LOADER.LOADING,
        //     payload: false,
        //   });
        // }, 500);
        return resolve(response.data);
      })
      .catch((err) => {
        // dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getVersionData = (survey_id, version, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING , payload:true});

  return new Promise((resolve, reject) =>
    axios
      .get(`${domain2}survey/survey-info/${survey_id}/${version}`)
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
        setTimeout(() => {
          dispatch({
            type: SET_LOADER.LOADING,
            payload: false,
          });
        }, 500);
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getAllThemes = (callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING , payload:true});

  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}themes`)
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
        setTimeout(() => {
          dispatch({
            type: SET_LOADER.LOADING,
            payload: false,
          });
        }, 500);
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const configureSettings = (body,cb) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .post(`${domain}settings`, body)
      .then((response) => {
        // if (callback) {callback(response.data)}
        cb()
        setTimeout(() => {
          dispatch({
            type: SET_LOADER.LOADING,
            payload: false,
          });
        }, 500);
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getSurveyById = (id, callback) => (dispatch) => {
  // dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}survey/survey-details/${id}`)
      .then((response) => {
        // setTimeout(()=>{
        if (response && response.statusCode === 200) {
          if (callback) {
            callback();
          }
          // dispatch({
          //   type: SET_LOADER.LOADING,
          //   payload: false,
          // });
        }

        // },4000)
        var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        // console.log('surveydata original',response.data.data)
        var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        if (
          response.data.data &&
          response.data.data.description &&
          response.data.data.instructions
        ) {
          if (
            response.data.data.description.charAt(
              response.data.data.description.length - 1
            ) == "=" ||
            response.data.data.instructions.charAt(
              response.data.data.instructions.length - 1
            ) == "=" ||
            base64regex.test(response.data.data.instructions) ||
            base64regex.test(response.data.data.description)
          ) {
            response.data.data.description = Buffer.from(
              response.data.data.description,
              "base64"
            ).toString("ascii");
            response.data.data.instructions = Buffer.from(
              response.data.data.instructions,
              "base64"
            ).toString("ascii");
          }
        } else {
          response.data.data.description = response.data.data.description || "";
          response.data.data.instructions =
            response.data.data.instructions || "";
        }
        dispatch({
          type: GET_SURVEY_DETAILS,
          payload: response.data.data,
        });
        // dispatch({ type: SET_LOADER.LOADING, payload: false });
        return resolve(response.data);
      })
      .catch((err) => {
        // dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const updateSurvey = (id, payload, org_id) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .put(`${domain}survey/modify/${id}`, payload)
      .then((response) => {
        toast.success(response.data.message);

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        if (org_id) {
          dispatch(getAllSurveys(org_id));
        }
        dispatch({
          type: GET_SURVEY_DETAILS,
          payload: response.data.data,
        });
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const updateSelfSurvey = (id, payload) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axiosObj
      .put(`${domain2}survey/update/${id}`, payload)
      .then((response) => {
        toast.success(response.data.message);

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        dispatch({
          type: GET_SURVEY_DETAILS,
          payload: response.data.data,
        });
        return resolve(response.data);
      })
      .catch((err) => {
        //  console.log("err",err.response.data.message)

        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getLanguageFiles = (id, language) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .get(`${domain}survey/export-question/${id}/${language}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getQuestbySection = (id, language) => (dispatch) => {
  // dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .get(`${domain2}survey/question-section/${id}`)
      .then((response) => {
        // setTimeout(() => {
        //   dispatch({
        //     type: SET_LOADER.LOADING,
        //     payload: false,
        //   });
        // }, 2000);
        dispatch({
          type: GET_QUEST_SECTION,
          payload: response.data.data,
        });
        return resolve(response.data);
      })
      .catch((err) => {
        // dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const updateQuestion = (id, language, payload, callback) => (
  dispatch
) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .put(`${domain}survey/question?id=${id}&language=${language}`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const checkSurveyName = (name, id) => (dispatch) => {
  dispatch({ type: SET_FIELD_LOADER, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .get(
        id
          ? `${domain}survey/verify-name/${name}/${id}`
          : `${domain}survey/verify-name/${name}`
      )
      .then((response) => {
        if (response) {
          dispatch({
            type: SET_FIELD_LOADER,
            payload: false,
          });
          return resolve(response.data);
        }
      })
      .catch((err) => {
        const { response = {} } = err;
        const { data = {} } = response;
        dispatch({ type: SET_FIELD_LOADER, payload: false });
        dispatch({ type: ERROR_MSG, payload: data && data.message });
        return reject(err);
      })
  );
};

export const updateIncentiveAmount = (payload, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .put(`${domain}survey/incentive`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const googleTranslate = (selectedLang, text, cb) => {
  let fromLang = "en";
  let toLang = selectedLang.toLowerCase(); // translate to norwegian

  const API_KEY = ["AIzaSyDLMChBKwyQWbT4_b6C8KjvNGHiYPl8c9o"];

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
  url += "&q=" + encodeURI(text);
  url += `&source=${fromLang}`;
  url += `&target=${toLang}`;

  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      if (cb) {
        cb(response.data.translations[0].translatedText);
      }
    });
};

export const uploadAudience = (payload, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios4
      .post(`${domain4}audience`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const deleteAudience = (payload, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios4
      .delete(`${domain4}audience`, { data: payload })
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getAudience = (userQuery, survey_id) => async (dispatch) => {
  const query = userQuery || {
    limit: 30,
    page: 0,
    startdate: "",
    enddate: "",
    search: "",
  };

  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios4
      .get(
        `${domain4}audience/list?survey_id=${survey_id}&limit=${
          query.limit
        }&page=${query.page}${query.startdate &&
          `&dateFrom=${query.startdate}`}${query.enddate &&
          `&dateTo=${query.enddate}`}${query.search &&
          `&search=${query.search}`}`
      )
      .then((response) => {
        dispatch({
          type: AUDIENCE_lIST,
          payload: {
            data: response?.data?.data?.record,
            count: response?.data?.data?.count,
          },
        });

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const resendAudienceLink = (payload, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios4
      .post(`${domain4}audience/resend_link`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const updateAudienceQueries = (query) => ({
  type: UPDATE_AUDIENCE_QUERY,
  payload: query,
});

export const addAudience = (payload, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios4
      .post(`${domain4}audience/add`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const editAudience = (payload, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios4
      .put(`${domain4}audience/edit`, payload)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const deleteAllAudience = (surveyId, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios4
      .delete(`${domain4}audience/survey-audience/${surveyId}`)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const resendLinkToAll = (surveyId, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios4
      .post(`${domain4}audience/resend-link-survey-all-audience/${surveyId}`)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const updateSurveySettings = (surveyId, payload) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios
      .put(`${domain2}survey/update-survey-setting-fields/${surveyId}`, payload)
      .then((response) => {
        toast.success(response.data.message);

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const getEmailTemplates = (orgId, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING , payload:true});

  return new Promise((resolve, reject) =>
    axios
      .get(
        `${domain}settings/organization-email-template?organization_id=${orgId}`
      )
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
        setTimeout(() => {
          dispatch({
            type: SET_LOADER.LOADING,
            payload: false,
          });
        }, 500);
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const editEmailTemplate = (payload,callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING , payload:true});

  return new Promise((resolve, reject) =>
    axios
      .put(
        `${domain}settings/edit-organization-email-template`,payload
      )
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback(response.data);
        }
        setTimeout(() => {
          dispatch({
            type: SET_LOADER.LOADING,
            payload: false,
          });
        }, 500);
        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};

export const deleteSurvey = (id, callback) => (dispatch) => {
  dispatch({ type: SET_LOADER.LOADING, payload: true });

  return new Promise((resolve, reject) =>
    axios4
      .delete(`${domain}common/survey/${id}`)
      .then((response) => {
        toast.success(response.data.message);
        if (callback) {
          callback();
        }

        dispatch({
          type: SET_LOADER.LOADING,
          payload: false,
        });

        return resolve(response.data);
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER.LOADING, payload: false });
        return reject(err);
      })
  );
};
