import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components


import pagesRoutes from "../routes/pagesRoutes";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";


import { connect } from 'react-redux';
import { Suspense } from "react";

class Pages extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        {/* <PagesHeader {...rest} /> */}
        <div className={classes.wrapper} ref="wrapper">
          <div
           
            // style={{ backgroundImage: "url(" + bgImage + ")", padding: '0px' }}
          >
            <Suspense fallback={""}>
            <Switch>
              {pagesRoutes.map((prop, key) => {
                if (prop.collapse) {
                  return null;
                }
                if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                }
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
            </Suspense>
            
            {/* <Footer white /> */}
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => ({

})

export default connect(null, mapDispatchToProps)(withStyles(pagesStyle)(Pages));
