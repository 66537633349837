import { createMuiTheme} from '@material-ui/core/styles';

let hexColor = "#1469b2"
let dangerColor = "#930A2B"
export const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      textPrimary: {
        backgroundColor: hexColor ,
        color: 'white',
        '&:hover': {
          backgroundColor: hexColor  
        },
        '&:focus': {
          backgroundColor: hexColor ,
        },
        '&:active': {
          backgroundColor: hexColor ,
        }
      },
      root: {
        '&:hover':{
          opacity:0.9
        }
      },
      containedPrimary:{
        '&:hover':{
          backgroundColor : hexColor 
        }
      }
    }, 
    MuiIconButton:{
      root:{
        '&:hover':{
          backgroundColor:"none"
        }
      }
    }, //Button overrides
    MuiTextField: {
      root: {
        color: hexColor ,
        '&:hover':{
          color:hexColor ,
        },  "& .Mui-error": {
          color:dangerColor
        },
        "& .MuiFormHelperText-root": {
          color:dangerColor
        }
      }
    },
    MuiFormHelperText:{
      root:{
        color:dangerColor
      }
    },
    MuiButtonBase:{
      root:{
        color:hexColor
      }
    }
  },
  MuiFocused: {
    color: hexColor 
  },
  MuiInputLabel: {
    '&:hover': {
      color: hexColor 
    }
  },
  MuiInputBase: {
    '&:hover': {
      color: hexColor 
    }
  },//Text Field overrides
  MuiRadio: {
    root: {
      color: hexColor ,
      '&$checked': {
        color: hexColor ,
      }
    }
  },//Radio Button overrides
  MuiCheckbox: {
    root: {
      color: hexColor,
      '&$checked': {
        color: hexColor,
      }
    }
  },//Check box overrides
  MuiStepper:{
    root:{
      color: hexColor ,
    }
  },
  MuiStepIcon:{
    root:{
      color: hexColor,
      '&:active':{
        fill:hexColor,
        color: hexColor,
        backgroundColor: hexColor
      },
      '&:completed':{
        color: hexColor
      }
    },
    active:{
      color:hexColor,
      background:hexColor
    }
  },
  // typography: {
  //   fontFamily: font
  // },
  palette: {
    primary: {
      500: hexColor,
      main: hexColor
    },
    secondary: {
      500: "#444444",
      main: "#444444"
    },
    contrastText: "#fff",
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});