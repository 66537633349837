const ADMIN_ACTIONS = {
  LOGIN: 'ADMIN::LOGIN',
  LOGIN_ERROR: 'ADMIN::LOGIN_ERROR',
  POST_LOGIN_ERROR: 'ADMIN::POST_LOGIN_ERROR',
  POST_LOGIN_SUCCESS: 'ADMIN::POST_LOGIN_SUCCESS',
  FORGOT_PASS_ERROR: 'ADMIN::FORGOT_PASS_ERROR',
  CODE_VER_ERROR:'ADMIN::CODE_VER_ERROR',
  OTP_VER_ERROR: 'ADMIN::OTP_VER_ERROR',
  RESET_PSS_ERROR:'ADMIN::RESET_PSS_ERROR',
  REGISTER_ERROR:'ADMIN::REGISTER_ERROR',
  DAILY_CONSUMPTION:'ADMIN::DAILY_CONSUMPTION'
};
export default ADMIN_ACTIONS
