import {
  APPOINTMENT_lIST,
  UPDATE_QUERY,
  APPOINTMENT_BOOKING_FIELDS,
  REQUEST_CALLBACK_LIST,
  UPDATE_CALLBACK_QUERY,
  AGENT_LIST,
  UPDATE_AGENT_QUERY,
  SLOT_DATA,
  UPDATE_SLOT_DATE,
  UPDATE_COPY_DATE,
  UPDATE_SLOT_AGENT_COUNT,
  TOGGLE_ALL_APPOINTMENTS,
  SET_ADMIN_ROLES,
  SET_MODULE_ROLES,
  SET_MODULE_NAMES,
  INTERVIEWER_lIST,
  AUDIENCE_lIST,
  STOP_SCROLL,
  // GET_MODULES
} from "../actionTypes/keppel_action_type";

import {
  GET_USERS,
  GET_USER,
  UPDATE_USER_QUERY,
  EXPORT_DATA,
  GET_UPDATES,
  UPDATE_INTERVIEWER_QUERY,
  UPDATE_AUDIENCE_QUERY,
  SESSION_EXPIRY_WARNING,
} from "../actionTypes/user_action_type";

import moment from "moment";

const initialState = {
  sessionWarning: false,
  stopScroll:false,
  appointmentList: {
    data: [],
    count: 0,
  },
  callbackList: {
    data: [],
    count: 0,
  },
  appointmentQuery: {
    limit: 30,
    skip: 0,
    property: "all",
    startdate: "",
    enddate: "",
    mode: "all",
    sort: 0,
  },
  callbackQuery: {
    limit: 30,
    skip: 0,
    startdate: null,
    enddate: null,
  },
  appointmentBookingFields: [],
  agentsList: {
    data: [],
    count: 0,
  },
  agentQuery: {
    limit: 30,
    skip: 0,
  },
  slotData: {
    data: [],
    count: 0,
    slotDate: moment(new Date().getTime() + 60 * 60 * 24 * 1000),
    copyDate: null,
    property: null,
    slots: null,
    isUpdated: false,
  },
  areAppointmentsEnabled: true,
  adminRoles: {},
  moduleRoles: {},
  userQuery: {
    limit: 30,
    skip: 0,
    startdate: "",
    enddate: "",
    search: "",
  },
  interviewerQuery: {
    limit: 30,
    page: 0,
    startdate: "",
    enddate: "",
    search: "",
  },
  audienceQuery: {
    limit: 30,
    page: 0,
    startdate: "",
    enddate: "",
    search: "",
  },
  users: [],
  updates: [],
  userCount: 0,
  userSessions: [],
  transcriptData: [],
  transcriptQuery: {
    limit: 50,
    skip: 0,
    startdate: "",
    enddate: "",
  },
  user: {},
  modules: [],
  export: {},
  moduleNames: {},
  interviewers: [],
  interviewers_count: null,
  audiences: [],
  audience_count: null,
};

const keppel_reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case APPOINTMENT_lIST:
      return {
        ...state,
        appointmentList: action.payload.data,
      };
    case INTERVIEWER_lIST:
      return {
        ...state,
        interviewers: action.payload.data,
        interviewers_count: action.payload.count,
      };
    case AUDIENCE_lIST:
      return {
        ...state,
        audiences: action.payload.data,
        audience_count: action.payload.count,
      };
    case UPDATE_QUERY:
      return {
        ...state,
        appointmentQuery: {
          ...action.payload,
        },
      };
    case AGENT_LIST:
      return {
        ...state,
        agentsList: action.payload.data,
      };
    case SLOT_DATA:
      return {
        ...state,
        slotData: { ...state.slotData, ...action.payload.data },
      };
    case UPDATE_AGENT_QUERY:
      return {
        ...state,
        agentQuery: {
          ...action.payload,
        },
      };
    case REQUEST_CALLBACK_LIST:
      return {
        ...state,
        callbackList: action.payload.data,
      };
    case UPDATE_CALLBACK_QUERY:
      return {
        ...state,
        callbackQuery: {
          ...action.payload,
        },
      };
    case APPOINTMENT_BOOKING_FIELDS:
      return {
        ...state,
        appointmentBookingFields: action.payload,
      };
    case UPDATE_SLOT_DATE:
      return {
        ...state,
        slotData: { ...state.slotData, slotDate: action.payload },
      };
    case UPDATE_COPY_DATE:
      return {
        ...state,
        slotData: {
          ...state.slotData,
          copyDate: action.payload.date,
          isUpdated: action.payload.isUpdated,
        },
      };
    case UPDATE_SLOT_AGENT_COUNT:
      return {
        ...state,
        slotData: { ...state.slotData, data: action.payload },
      };
    case TOGGLE_ALL_APPOINTMENTS:
      return { ...state, ...action.payload };
    case SET_ADMIN_ROLES:
      return { ...state, adminRoles: action.payload };
    case GET_USERS:
      return {
        ...state,
        users: action.payload.user,
        userCount: action.payload.count,
      };
    case UPDATE_USER_QUERY:
      return { ...state, userQuery: { ...action.payload } };
    case UPDATE_INTERVIEWER_QUERY:
      return { ...state, interviewerQuery: { ...action.payload } };
    case UPDATE_AUDIENCE_QUERY:
      return { ...state, audienceQuery: { ...action.payload } };
    case SET_MODULE_ROLES:
      return { ...state, moduleRoles: action.payload };
    case SET_MODULE_NAMES:
      return { ...state, moduleNames: action.payload };
    case GET_USER:
      return { ...state, user: action.payload.user };
    case GET_UPDATES:
      return { ...state, updates: action.payload };
    case EXPORT_DATA:
      return { ...state, export: action.payload.data };
    case SESSION_EXPIRY_WARNING:
      return { ...state, sessionWarning: action.payload };
      case STOP_SCROLL:
      return { ...state, stopScroll: action.payload };
    // case GET_MODULES:
    //   return { ...state, modules:action.payload && action.payload.record}
    // case MODULE_ROLES:{
    //   return { ...state, moduleRoles: action.payload}
    // }
    default:
      return state;
  }
};

export default keppel_reducer;
