import React from "react";

const NotFound = () => {
    return (
        <div className="align-center">
            <h4 className="login-txt">404. Not Found!</h4>
        </div>
    )
}

export default NotFound