import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// core components


import Slide from '@material-ui/core/Slide';
import { logout } from '../../../actions/admin_action'



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class ConfirmationModel extends Component {

  render() {
    const { show, handleClose, msg, title,display,role, cancelTxt, actionText, action, secondaryAction, children ,id} = this.props;
    return (
      <div id='new_version'>
        {/* <!-- Modal --> */}
        <div className= {show?"modal fade blck-backdrop text-center show":"modal fade blck-backdrop text-center"} style={show?{display:"block"}:show===false?{display:"none"}:{}} id={`${id}`} tabindex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                {title && <h4 className="heading-title">{title}</h4>}
                <h5 className='mt-3'> {msg} </h5>
              </div>
              <div className="modal-footer border-0 flex-column">
                <button type="button" className="btn lg-blue-btn" onClick={action}>{actionText}</button>

                <button type="button" className="btn white-btn" data-bs-dismiss="modal" onClick={secondaryAction}>{cancelTxt}</button>
              </div>
            </div>
          </div>
        </div>

        {/* <Dialog
          maxWidth={'md'}
          open={show}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              width: '30%'
            },
          }}
        >
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>{title}</DialogTitle>
          <DialogContent style={{ overflowY: "hidden", width: '600px', maxWidth: '100%' }}>
            <Typography style={{ textAlign: "center", marginTop: "-10px" }}>{msg}</Typography>
            {children}
          </DialogContent>
          {
            actionText ?
              <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '0px' }}>
                <Button variant="contained" color="rose" onClick={action} style={{ width: '80%', fontWeight: "bold", fontSize: "14px" }}>
                  {actionText}
                </Button>
              </div>
              :
              null
          }
          <div style={{ textAlign: 'center', margin: '10px', marginTop: '0px' }}>
            <Btn color="rose" onClick={secondaryAction} style={{ width: '52%', fontSize: "13px" }}>
              {cancelTxt}
            </Btn>
          </div>
        </Dialog> */}
      </div>
    )

  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: (history) => dispatch(logout(history))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(ConfirmationModel))
