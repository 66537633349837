export const LOGIN= 'login';
export const LOGIN_ERROR= 'login-error';
export const POST_LOGIN_ERROR= 'post-login-error';
export const POST_LOGIN_SUCCESS= 'post-login-success';
export const GET_USERS= 'get-users';
export const GET_USER= 'get-user';
export const GET_USERS_ERROR= 'get-users-error';
export const GET_REPORTS= 'get-reports';
export const GET_REPORTS_ERROR= 'get-reports-error';
export const INCREASE_REQUEST= 'increase-request';
export const DECREASE_REQUEST= 'decrease-request';
export const LOGOUT= 'logout';
export const SUCCESS_MSG= 'success-msg';
export const CLEAR_ERROR= 'clear-error';