import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import {
  Slide, IconButton, InputAdornment, DialogActions, DialogContent,
  FormHelperText
}
  from '@material-ui/core';
import { toast } from 'react-toastify';
import ADMIN_ACTIONS from '../../../actionTypes/ADMIN_ACTIONS';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import { resetPassword, logout } from '../../../actions/admin_action'
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import validateResetPassword from "../../../utils/validations/validateResetPassword";
import { adminErrors } from "../../../constants/errors";



export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfPassword: false,
      password: "",
      confirmPassword: "",
      email: "",
      errors: {},
      showCurrentPassword: false,
      currentPassword: "",
    };
  }

  handleClickShowOldPassword = () => {
    this.setState({
      showOldPassword: !this.state.showOldPassword,
    });
  };

  handleClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };

  handleClickShowCurrentPassword = () => {
    this.setState({
      showCurrentPassword: !this.state.showCurrentPassword,
    });
  };

  handleClickShowConfPassword = () => {
    this.setState({
      showConfPassword: !this.state.showConfPassword,
    });
  };

  onChange = (e, prop) => {
    const { value } = e.target;
    this.setState({
      ...this.state,
      [prop]: value,
    });
  };

  isValidInput = () => {
    const { password, confirmPassword, currentPassword } = this.state;
    let { isValid, error } = validateResetPassword(
      {
        newPass: password,
        confirmPass: confirmPassword,
        currentPassword: currentPassword,
      },
      { validateCurrentPass: true }
    );
    if (!isValid) {
      this.setState({
        errors: { ...error },
      });
    }
    return isValid;
  };

  onCancel = () => {
    if (this.props.hideCancel) {
      toast.error(adminErrors.forcePasswordChange);
      // this.props.setSnackBar(adminErrors.forcePasswordChange)
    } else {
      this.props.handleClose();
    }
  };

  resetPassword = () => {
    const { password, confirmPassword, currentPassword } = this.state;
    if (this.isValidInput()) {
      this.setState({ errors: {} });
      let payload = {
        password,
        confirmPassword,
        currentPassword: currentPassword,
      };
      this.props.resetPassword(
        payload,
        () => {
          // this.props.logout(this.props.history);
          window.location.reload();
        },
        this.props.history
      );
    }
  };

  render() {
    const { show, handleClose, hideCancel } = this.props;
    const {
      password,
      confirmPassword,
      showNewPassword,
      showConfPassword,
      showCurrentPassword,
      errors,
      currentPassword,
    } = this.state;
    return (
      <div id="new_version" className="updatePassword">
        {/* <!-- update password popup in bootstrap --> */}
        {/* Need here to add show class only on load for show popup in (modal fade blck-backdrop show)  */}

        <div
          class="modal fade blck-backdrop show"
          id="updateModal"
          tabindex="-1"
          aria-labelledby="updateModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content text-center">
              <div class="modal-body">
                <h4 id="form-dialog-title">Change Password</h4>
                <form action="">
                  <div class="form-floating passTxt">
                    <input
                      type={showCurrentPassword ? "text" : "password"}
                      value={currentPassword}
                      onChange={(e) => this.onChange(e, "currentPassword")}
                      class="form-control"
                      id="floatingInput"
                      placeholder="Current Password"
                    />
                    <label for="floatingInput">Current Password</label>

                    <InputAdornment className="eyeIcon" position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowCurrentPassword}
                      >
                        {showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                    {errors && errors.currentPassword && (
                      <FormHelperText className="error">
                        {errors && errors.currentPassword}
                      </FormHelperText>
                    )}
                  </div>
                  <div class="form-floating passTxt">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => this.onChange(e, "password")}
                      class="form-control"
                      id="floatingPassword1"
                      placeholder="New Password"
                    />
                    <label for="floatingPassword1">New Password</label>

                    <InputAdornment className="eyeIcon" position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowNewPassword}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    {errors && errors.newPass && (
                      <FormHelperText className="error">
                        {errors && errors.newPass}
                      </FormHelperText>
                    )}
                  </div>

                  <div class="form-floating passTxt">
                    <input
                      type={showConfPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => {
                        this.onChange(e, "confirmPassword");
                      }}
                      class="form-control"
                      id="floatingPassword2"
                      placeholder="New Password"
                    />
                    <label for="floatingPassword2">Confirm Password</label>

                    <InputAdornment className="eyeIcon" position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowConfPassword}
                      >
                        {showConfPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    {errors && errors.confirmPass && (
                      <FormHelperText className="error">
                        {errors && errors.confirmPass}
                      </FormHelperText>
                    )}
                  </div>
                  <div className="form-group py-3">
                    <p>
                      Password should be of a minimum length of eight (8)
                      characters with at least three of the following character
                      types: upper case letters (A-Z), lower case letters (a-z),
                      digits (0-9) and symbols.
                    </p>
                  </div>
                  <div class="d-flex justify-content-end gap-3">
                    <button
                      type="button"
                      onClick={this.resetPassword}
                      class="btn blue-btn btn-md"
                      disabled={
                        !password ||
                        !confirmPassword ||
                        this.state.newPassError ||
                        this.state.confirmPassError
                      }
                    >
                      Submit
                    </button>
                    <button
                      onClick={this.onCancel}
                      type="button"
                      class="btn btn-white btn-md"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <Dialog
          maxWidth={'md'}
          open={show}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              width: '30%'
            },
          }}
          disableBackdropClick={hideCancel ? true : false}
        >
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Change Password</DialogTitle>
          <DialogContent style={{ overflowY: "hidden", width: '600px', maxWidth: '100%', marginTop: "-25px" }}>
           
            <FormControl fullWidth style={{ marginTop: '15px' }}>
              <InputLabel htmlFor="adornment-password2">Current password</InputLabel>
              <Input
                id="adornment-password2"
                type={showCurrentPassword ? 'text' : 'password'}
                fullWidth
                value={currentPassword}
                // error={this.state.currentPassword}
                helperText={this.state.currentPassword}
                onChange={(e) => this.onChange(e, "currentPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowCurrentPassword}>
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors && errors.currentPassword && <FormHelperText>{errors && errors.currentPassword}</FormHelperText>}
            </FormControl>
           
            <FormControl fullWidth style={{ marginTop: '15px' }}>
              <InputLabel htmlFor="adornment-password2">New password</InputLabel>
              <Input
                id="adornment-password2"
                type={showNewPassword ? 'text' : 'password'}
                fullWidth
                value={password}
                error={this.state.newPassError}
                helperText={this.state.newPassError}
                onChange={(e) => this.onChange(e, "password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowNewPassword}>
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors && errors.newPass && <FormHelperText>{errors && errors.newPass}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth style={{ marginTop: '15px' }}>
              <InputLabel htmlFor="adornment-password3">Confirm password</InputLabel>
              <Input
                id="adornment-password3"
                type={showConfPassword ? 'text' : 'password'}
                fullWidth
                value={confirmPassword}
                error={this.state.confirmPassError}
                onChange={(e) => { this.onChange(e, 'confirmPassword') }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowConfPassword}>
                      {showConfPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors && errors.confirmPass &&
                <FormHelperText>{errors && errors.confirmPass}</FormHelperText>
              }
            </FormControl>
            <p style={{ marginTop: "20px", textAlign: "center" }}>
              Password should be of a minimum length of eight (8) characters with at least three of the following character types: upper case letters (A-Z), lower case letters (a-z), digits (0-9) and symbols.
            </p>
          </DialogContent>
          <DialogActions style={{ textAlign: "center", display: "flex", marginTop: "-20px" }}>
            <div style={{ textAlign: 'center', margin: '5px' }}>
              <Button variant="contained" color="rose" onClick={this.resetPassword}
                disabled={!password || !confirmPassword || this.state.newPassError || this.state.confirmPassError}
                style={{ width: '80%', fontWeight: "bold", fontSize: "14px" }}
              >
                Submit
              </Button>
            </div>

            <div style={{ textAlign: 'center', margin: '5px', marginRight: '16px' }}>
              <Btn color="rose" onClick={this.onCancel} style={{ padding: "12px 15px", lineHeight: "1.42857143", fontWeight: "bold", fontSize: "14px" }}>
                Cancel
              </Btn>
            </div>


          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (obj, callback) => dispatch(resetPassword(obj, callback)),
    logout: (history) => dispatch(logout(history)),
    setSnackBar: (msg) =>
      dispatch({
        type: ADMIN_ACTIONS.POST_LOGIN_ERROR,
        payload: msg,
      }),
  };
};

export default withRouter(
  withStyles(loginPageStyle)(connect(null, mapDispatchToProps)(ResetPassword))
);
