import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { getUserProfile } from "../../actions/user_action";
import {
  axiosInstnace as axios,
  domain,
  isAuthenticated,
} from "../../utils/util";
class AuthGuardImp extends Component {
  componentDidMount = () => {
    if (window.location.pathname.includes("login-page")) {
    } else {
      // this.props.getUserProfile()
      // .then((res) => {
      //   if (res) {
      //     const { data = {} } = res
      //     const isSuperadmin = data && data.is_superadmin;
      //     if (!isSuperadmin) {
      //       axios.get(`${domain}user/fetchUserModuleRole`)
      //         .then((resp) => {
      //           const response = resp && resp.data && resp.data.data
      //           const moduleNames = (response && response.map((rol => rol.module_name))) || []
      //           let { location } = this.props;
      //           let { pathname } = location;
      //           let path = pathname.slice(pathname.lastIndexOf('/') + 1);
      //           if(
      //             path === "auditlogs" && (moduleNames.indexOf("Audit Logs") === -1) ||
      //             path === "responses" && (moduleNames.indexOf("Responses") === -1) ||
      //             path === "analytics" && (moduleNames.indexOf("Analytics") === -1) ||
      //             path === "users" && (moduleNames.indexOf("User Management") === -1) ||
      //             path === "incentivelogs" && (moduleNames.indexOf("Incentive Logs") === -1) ||
      //             path === "editsurvey" && (moduleNames.indexOf("Survey") === -1) ||
      //             path === "addsurvey"
      //           ){
      //             this.props.history.push('/restrict')
      //           }
      //           // if (path === 'auditlogs' && (moduleNames.some((mod)=> mod !== "Audit Logs")) ||
      //           //   (path === 'responses' && (moduleNames.some((mod)=> mod !== "Responses"))) ||
      //           //   (path === 'analytics' && (moduleNames.some((mod)=> mod !== "Analytics"))) ||
      //           //   (path === 'users' && (moduleNames.some((mod)=> mod !== "User Management"))) ||
      //           //    (path === 'incentivelogs' && (moduleNames.some((mod)=> mod !== "Incentive Logs"))) ||
      //           //     (path === 'editSurvey' && (moduleNames.some((mod)=> mod !== "Survey")))
      //           //     // ||
      //           //     // (path === 'Survey' && (moduleNames.some((mod)=> mod !== "Survey")))
      //           //   // (path === 'imagevetting' && roles.indexOf("Images Vetting") === -1) ||
      //           //   // ((path === 'managefaq' || path === 'viewfaqcat' || path === 'addfaq' || path === 'viewfaq' || path === 'editfaq' ||
      //           //   // path === 'managecontent' || path === 'viewcontent' || path === 'editcontent') && roles.indexOf("Manage Content") === -1)
      //           //   // ||
      //           //   // (path === 'managereports' && roles.indexOf("Manage Reports") === -1) || path === "survey" || path === "audit"
      //           // ) {
      //           //   this.props.history.push('/restrict');
      //           // }
      //         })
      //     }
      //   }
      // })
    }
  };

  render() {
    const { component: Template, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated() ? (
            <Template {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminRoles: state.keppel_reducer.adminRoles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(getUserProfile()),
  };
};

AuthGuardImp.propTypes = {
  component: PropTypes.any.isRequired,
  // loadAuthData: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuardImp);
