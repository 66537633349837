import { GET_RESPONSES, UPDATE_RESPONSE_QUERY, GET_RESPONSE,
            GET_FRAUD_STATUS,EXPORT_RESPONSE_DATA, GET_RESPONSE_INFO 
        }
 from '../actionTypes/response_action_type';

const initialState = {
    responses: [],
    responseCount: 0,
    responseQuery: {
        page: 1,
        limit: 30,
        status: "",
        search: undefined,
        dateFrom: '',
        dateTo: '',
        user_type:"All"
    },
    responseDetail:{},
    fraudStatus:'',
    responseData:{},
    moreInfo:{}
}

const response_reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_RESPONSES:
            return { ...state, responses: action.payload.response, responseCount: action.payload.count }
        case UPDATE_RESPONSE_QUERY:
            return { ...state, responseQuery: { ...action.payload } }
        case GET_RESPONSE:{
            return {...state, responseDetail:{...action.payload}}
        }
        case GET_FRAUD_STATUS:{
            return {...state, fraudStatus:{...action.payload}}
        }
        case EXPORT_RESPONSE_DATA:{
            return {...state, responseData:{...action.payload}}
        }
        case GET_RESPONSE_INFO:{
            return {...state,moreInfo:{...action.payload}}
        }
        default:
            return { ...state }
    }
}

export default response_reducer
