import {GET_AUDITLOGS, UPDATE_AUDIT_QUERY, GET_AUDIT_DETAIL, EXPORT_AUDIT_DATA, GET_AUDIT_FILTERS} 
    from '../actionTypes/audit_action_type';

const initialState = {
    auditLogs: [],
    auditLogsCount: 0,
    auditLogsQuery: {
        page: 1,
        limit: 30,
        status: "",
        search: undefined
    },
    auditLogDetail: {},
    responseData: {},
    auditFilters:{}
}

const audit_reducer = (state = initialState, action={}) => {
    switch (action.type) {
        case GET_AUDITLOGS:
            return { ...state, auditLogs: action.payload.auditLogs, auditLogsCount: action.payload.count }
        case UPDATE_AUDIT_QUERY:
            return { ...state, auditLogsQuery: { ...action.payload } }
        case GET_AUDIT_DETAIL: {
            return { ...state, auditLogDetail: { ...action.payload } }
        }
        case GET_AUDIT_FILTERS: {
            return { ...state, auditFilters: { ...action.payload } }
        }
        case EXPORT_AUDIT_DATA: {
            return { ...state, responseData: { ...action.payload } }
        }
        default:
            return { ...state }
    }
}

export default audit_reducer
