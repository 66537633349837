import React, { Suspense } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardRoutes from "../routes/dashboard";
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/appStyle.jsx";

import IdleTimer from "react-idle-timer";
import { ConfirmationModel } from "../components/Common/LTA_Models/ConfirmationModel";
import { ResetPassword } from "../components/Common/LTA_Models/ResetPassword";
import { resetPassword, logout } from "../actions/admin_action";
import { fetchUserModuleRole, getUserProfile } from "../actions/user_action";
import { getAllSurveys, getSettings } from "../actions/survey_action";
import qs from "qs";
import ADMIN_ACTIONS from "../actionTypes/ADMIN_ACTIONS";

import { getData, removeData, setData } from "../utils/util";
import Snackbar from "../components/Snackbar/Snackbar";
import { Warning } from "@mui/icons-material";
import SidebarNew from "../components/Sidebar/SidebarNew.jsx";
import { appLogo } from "../appConfig.js";
import Countdown from "react-countdown";
import moment from "moment";
import { SESSION_EXPIRY_WARNING } from "actionTypes/user_action_type";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileOpen: false,
      miniActive: false,
      timeout: 900000,
      showModal: false,
      userLoggedIn: false,
      authToken: false,
      showResetPassModel: false,
      isTimedOut: false,
      success: "",
      error: "",
      modalId: "",
    };
    this.sessionTimeoutRef = React.createRef();
    this.idleTimer = null;
  }

  continue = () => {
    this.onHide();
    this.props.getUserProfile();
    this.props.getSettings();

    //clearTimeout(this.sessionTimeoutRef.current);
  };

  handleLogout = () => {
    this.onHide();
    this.props.logout(this.props.history);
    //   clearTimeout(this.sessionTimeoutRef.current);
  };

  onHide = () => {
    this.props.showSessionWarning(false);
  };

  componentDidMount() {
    this.props.getSettings((data) => {
      this.setState({ timeout: data?.data?.admin_session_expiry_time * 1000 });
    });
    const userProfile = getData("userProfile");

    const { force_password_change, is_superadmin } = userProfile.data || {};
    if (
      force_password_change &&
      (!is_superadmin || is_superadmin == "super_sub_admin")
    ) {
      this.setState({ showResetPassModel: true });
    }
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== "/maps/full-screen-maps";
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  getSwitchRoutes = () => {
    return (
      <Suspense fallback={""}>
        <Switch>
          {dashboardRoutes.map((prop, key) => {
            if (prop.redirect)
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            if (prop.collapse)
              return prop.views.map((viewProp, viewKey) => {
                const allViews = [];
                if (viewProp.subviews) {
                  allViews.push(
                    ...viewProp.subviews.map((subviewProp, subviewKey) => (
                      <Route
                        path={subviewProp.path}
                        component={(renderProps) => (
                          <subviewProp.component
                            {...renderProps}
                            id={subviewProp.id}
                          />
                        )}
                        key={subviewKey}
                      />
                    ))
                  );
                }

                allViews.push(
                  <Route
                    path={viewProp.path}
                    component={(renderProps) => (
                      <viewProp.component {...renderProps} id={viewProp.id} />
                    )}
                    key={viewKey}
                  />
                );
                return allViews;
              });
            return (
              <Route
                path={prop.path}
                component={(renderProps) => (
                  <prop.component {...renderProps} id={prop.id} />
                )}
                key={key}
              />
            );
          })}
        </Switch>
      </Suspense>
    );
  };

  getSurveyTitle = (type) => {
    const { surveyList } = this.props;
    const locationToParse = window.location.search.replace(/\?/g, "");
    const parsed = qs.parse(locationToParse);
    let survey_obj =
      surveyList &&
      surveyList.find((e) => {
        return e.id == parsed.surveyId;
      });

    if (type == "r") {
      return survey_obj ? `${survey_obj.title}` : "";
    } else {
      return survey_obj ? `${survey_obj.title}` : "";
    }
  };

  showHideResetPassModel = () => {
    this.setState({
      showResetPassModel: !this.state.showResetPassModel,
    });
  };

  // renderer = ({ hours, minutes, seconds, completed }) => {
  //   if (completed) {
  //     this.handleLogout();

  //     return null;
  //   } else {
  //     // Render a countdown

  //     if (
  //       this.state.showModal === false &&
  //       (hours * 3600 + minutes * 60 + seconds) * 1000 ===
  //         this.state.timeout - 300000
  //     ) {
  //       this.props.showSessionWarning(true);
  //     }
  //     return null;
  //   }
  // };

  render() {
    const { surveyList = [], classes, ...rest } = this.props;
    const locationToParse = window.location.search.replace(/\?/g, "");
    
    const parsed = qs.parse(locationToParse);
    let survey_id = parsed && parsed.surveyId;

    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
      });
    const { showModal = false, timeout } = this.state;
    const userProfile = getData("userProfile");
    const isDownloading = getData("downloading")
    return (
      <>
        <div
          className="Dashboard-wrapper-1"
          style={isDownloading ? { pointerEvents: "none" } : {}}
        >
          {/* {console.log("env",process.env)} */}

          <a href="/" className="mobile-logo">
            <img src={appLogo} alt="" className="show-lg" />
          </a>

          {/* <Countdown date={Date.now() + timeout} renderer={this.renderer} /> */}
          {/* <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onIdle={this.onIdle}
            debounce={250}
            timeout={timeout}
            onActive={this.onActive}
          /> */}
          <SidebarNew
            //surveyList={surveyList || []}
            history={this.props.history}
          />
          <div className="Dashboard-mainPanel-2">
            {this.getRoute() ? (
              <div style={{ padding: "0" }} id="dashboardWrap">
                {window.location.pathname.includes("/responses") ? (
                  <div className="breadcrumb">
                    <span
                      onClick={() => {
                        this.props.history.push(
                          `/admin/editsurvey?surveyId=${survey_id}`
                        );
                      }}
                    >
                      {this.getSurveyTitle("r")}
                    </span>
                    <span> &gt; Responses</span>
                  </div>
                ) : null}
                {window.location.pathname.includes("/incentivelogs") ? (
                  <div className="breadcrumb">
                    <span
                      onClick={() => {
                        this.props.history.push(
                          `/admin/editsurvey?surveyId=${survey_id}`
                        );
                      }}
                    >
                      {this.getSurveyTitle("r")}
                    </span>
                    <span> &gt; Incentive Logs</span>
                  </div>
                ) : null}

                <div id="dashboardInnerWrap">{this.getSwitchRoutes()}</div>
              </div>
            ) : (
              <div>{this.getSwitchRoutes()}</div>
            )}
          </div>

          {this.state.showResetPassModel && (
            <ResetPassword
              show={this.state.showResetPassModel}
              handleClose={this.showHideResetPassModel}
              hideCancel={true}
              resetPassword={this.props.resetPassword}
              history={this.props.history}
              logout={this.props.logout}
              showCurrentPass={false}
            />
          )}

          <Snackbar
            message="Your Free plan is completed. Please UPGRADE your plan to continue using the services."
            open={
              userProfile &&
              userProfile.data &&
              userProfile.data.subscriptionDetails &&
              userProfile.data.subscriptionDetails.subscription_quota_exceeded
            }
            color="danger"
            place="top-center"
            type="error"
            icon={Warning}
          />
        </div>
        {/*
       <ConfirmationModel
          title={"You have been idle!"}
          id={"Idle"}
          show={this.props.sessionWarning}
          handleClose={this.onHide}
          msg={
            "You will be logged out in 5 minutes. Do you want to continue this session?"
          }
          history={this.props.history}
          cancelTxt="Logout"
          actionText={"Continue"}
          action={this.continue}
          secondaryAction={this.handleLogout}
        />
      
      */}
      </>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  surveyList: state.survey_reducer.surveys,
  adminRoles: state.keppel_reducer.adminRoles,
  sessionWarning: state.keppel_reducer.sessionWarning,
  // postLoginError: state.admin_reducer.postLoginError,
  // postLoginSuccess: state.admin_reducer.postLoginSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  showSessionWarning: (status) =>
    dispatch({
      type: SESSION_EXPIRY_WARNING,
      payload: status,
    }),
  resetPassword: (obj, callback, hist) =>
    dispatch(resetPassword(obj, callback, hist)),
  removeSnackBar: () =>
    dispatch({
      type: ADMIN_ACTIONS.POST_LOGIN_SUCCESS,
      payload: "",
    }),
  removeErrSnackBar: () =>
    dispatch({
      type: ADMIN_ACTIONS.POST_LOGIN_ERROR,
      payload: "",
    }),
  logout: (history) => dispatch(logout(history)),
  fetchUserModuleRole: (isInit, loginMessage, hist) =>
    dispatch(fetchUserModuleRole(isInit, loginMessage, hist)),
  getUserProfile: () => dispatch(getUserProfile()),
  getSettings: (cb) => dispatch(getSettings(cb)),
  getAllSurveys: (org_id, callback) =>
    dispatch(getAllSurveys(org_id, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(appStyle)(Dashboard));
