import { SESSION_TIME_EXPIRY } from "actionTypes/user_action_type";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { getData } from "utils/util";
// import { CircularProgress } from "@material-ui/core";
const Loader = ({ isLoading }) => {
  let ref = useRef(null);

  useEffect(() => {
    if (isLoading) {
      ref.current.continuousStart();
    } else {
      ref.current.complete();
    }
  }, [isLoading]);

  return <LoadingBar color="#F85D18" ref={ref} />;
};

export default connect((state) => ({
  isLoading: state.loader_reducer.isLoading,
}))(Loader);
