import { fromJS } from 'immutable';

import {
  LOGIN_ERROR, CLEAR_ERROR, GET_USERS_ERROR, GET_REPORTS_ERROR, POST_LOGIN_ERROR, POST_LOGIN_SUCCESS
} from '../actions/admin_action_types';

let INITIAL_STATE= fromJS({
  login_error: null,
  get_user_error: null,
  get_report_error: null,
  post_login_error: null,
  post_login_success: null,
  
});

export default function(state= INITIAL_STATE, action={}) {
  switch(action.type) {
    case LOGIN_ERROR: {
      return state.set('login_error', action.payload)
    }
    case POST_LOGIN_ERROR: {
      return state.set('post_login_error', action.payload)
    }
    case POST_LOGIN_SUCCESS: {
      return state.set('post_login_success', action.payload)
    }
    case GET_USERS_ERROR: {
      return state.set('login_error', action.payload)
    }
    case GET_REPORTS_ERROR: {
      return state.set('get_report_error', action.payload)
    }
    case CLEAR_ERROR: {
      return state.set('state', INITIAL_STATE);
    }
    default:
      break;
  }
  return state;
}
