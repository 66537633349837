export const GET_USERS = "GET_USERS";
export const UPDATE_USER_QUERY = "UPDATE_USER_QUERY";
export const GET_USER = "GET_USER";
export const GET_MODULES = "GET_MODULES";
export const MODULE_ROLES = "MODULE_ROLES";
export const EXPORT_DATA = "EXPORT_DATA";
export const REDIRECT_USER = "REDIRECT_USER";
export const GET_UPDATES = "GET_UPDATES";
export const UPDATE_INTERVIEWER_QUERY = "UPDATE_INTERVIEWER_QUERY"
export const UPDATE_AUDIENCE_QUERY = "UPDATE_AUDIENCE_QUERY"
export const SESSION_EXPIRY_WARNING = "SESSION_EXPIRY_WARNING"