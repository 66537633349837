import React from "react";
import checkbox from "../../assets/images/ques-icons/checkbox.svg";
import radio from "../../assets/images/ques-icons/radio.svg";
import textbox from "../../assets/img/textbox.svg";
import dropdown from "../../assets/images/ques-icons/dropdown.svg";
import date from "../../assets/img/date.svg";
import clock from "../../assets/img/time.svg";
import csat from "../../assets/images/ques-icons/csat.svg";
import location from "../../assets/images/ques-icons/location.svg";
import number from "../../assets/img/number.svg";
import range from "../../assets/img/range.svg";
import rating from "../../assets/images/ques-icons/rating.svg";
import stat from "../../assets/images/ques-icons/static.svg";
import dob from "../../assets/img/date.svg";
import jump from "../../assets/img/jump-to.svg";
import trashIcon from "../../assets/img/redDeleteIcon.svg";
import delWhtIcon from "../../assets/img/delIcon.svg";
import addWht from "../../assets/img/add_white.svg";
import preview from "../../assets/img/preview.svg";
import link from "../../assets/images/link.svg";
import pause from "../../assets/images/pause.svg";
import share from "../../assets/images/share.svg";
import editIcon2 from "../../assets/images/doc2.svg";
import visaIcon from "../../assets/images/visa-icon.svg";
import noListFound from "../../assets/images/no_list_found.svg";
import noData from "../../assets/images/no_data.svg";

import mapImage from "../../assets/images/slider/Location.png";
import csatImage from "../../assets/images/slider/CSAT.png";
import ratingImage from "../../assets/images/slider/Rating.png";
import dateImage from "../../assets/images/slider/Date.png";
import orgLogo from "../../assets/images/affle-logo.svg";
import image1 from "../../assets/images/image_1.png";
import image2 from "../../assets/images/image_2.png";
import image3 from "../../assets/images/image_3.png";
import image4 from "../../assets/images/image_4.png";
import quesIcon from "../../assets/img/qus_icon.svg";
import plusIcon from "../../assets/img/plus_icon_lg.svg";
import signature from "../../assets/images/signature.png.png";
import dotMenu from "../../assets/images/dots_menus.svg";
import cloneIcon from "../../assets/images/clone.svg";
import imgIconBlack from "../../assets/img/imgIconBlack.svg";
import password from "../../assets/img/password.svg";
// function Image(props) {
//   return (
//     <div>
//         <img src={props?.img==='clock'?clock: props?.img==='checkbox'?checkbox: props?.img==='radio'?radio: ""} alt="" />
//     </div>
//   )
// }

export const Image = {
  clock: clock,
  checkbox: checkbox,
  radio: radio,
  textbox: textbox,
  dropdown: dropdown,
  date: date,
  csat: csat,
  location: location,
  number: number,
  range: range,
  rating: rating,
  stat: stat,
  dob: dob,
  jump: jump,
  trashIcon: trashIcon,
  delWhtIcon: delWhtIcon,
  addWht: addWht,
  preview: preview,
  link: link,
  pause: pause,
  share: share,
  editIcon2: editIcon2,
  visaIcon: visaIcon,
  noListFound: noListFound,
  noData: noData,
  mapImage: mapImage,
  csatImage: csatImage,
  ratingImage: ratingImage,
  dateImage: dateImage,
  orgLogo: orgLogo,
  image1: image1,
  image2: image2,
  image3: image3,
  image4: image4,
  quesIcon: quesIcon,
  plusIcon: plusIcon,
  sign: signature,
  dotMenu: dotMenu,
  cloneIcon: cloneIcon,
  imgIconBlack:imgIconBlack,
  password:password
};
