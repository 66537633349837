// @mui/icons-material
import Fingerprint from "@mui/icons-material/Fingerprint";

// import LoginPage from "../views/Pages/LoginPage";
// import ForgetPage from '../components/Custom/ForgetPassword';
// import ResetPassword from '../components/Custom/ResetPassword'
// import CodeVerification from "../components/Custom/CodeVerification";
// import ForgotSuccess from "../components/Custom/ForgotSuccess";
// import  RegistrationPage  from "../views/Pages/registration";
// import HomePage from "../views/Pages";

// import SelectPlan from "../views/Pages/SelectPlan";
// import RegisterSuccess from "../views/Pages/RegisterSuccess";
import { lazy } from "react";

const HomePage = lazy(()=>import("../views/Pages"))
const RegistrationPage = lazy(()=>import("../views/Pages/registration"))
const LoginPage =  lazy(()=>import("../views/Pages/LoginPage"))
const ForgetPage =  lazy(()=>import('../components/Custom/ForgetPassword'))
const ResetPassword = lazy(()=>import('../components/Custom/ResetPassword'))
const CodeVerification = lazy(()=>import("../components/Custom/CodeVerification"))

const SelectPlan = lazy(()=>import("../views/Pages/SelectPlan"))
const RegisterSuccess = lazy(()=>import("../views/Pages/RegisterSuccess"))
const Faq  = lazy(()=>import("../views/Pages/FAQ"))
const pagesRoutes = [
  // {
  //   path: '/home',
  //   name: "Login Page",
  //   short: "Login",
  //   mini: "LP",
  //   icon: Fingerprint,
  //   component: HomePage   
  // },
  {
    path: '/login',
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage   
  },
  {
    path: "/forgetpassword",
    name: "Forget Password",
    short: "Forget",
    mini: "LP",
    icon: Fingerprint,
    component: ForgetPage
  },
  {
    path: "/resetPassword",
    name: "Reset Password",
    short: "Forget",
    mini: "LP",
    icon: Fingerprint,
    component: ResetPassword
  },
  {
    path: "/codeVerification",
    name: "Code Verification",
    short: "code",
    mini: "LP",
    icon: Fingerprint,
    component: CodeVerification
  },
  // {
  //   path: "/emailsuccess",
  //   name: "Email success",
  //   short: "email",
  //   mini: "LP",
  //   icon: Fingerprint,
  //   component: ForgotSuccess
  // },
  {
    path: "/registration",
    name: "Registration",
    short: "reg",
    mini: "LP",
    icon: Fingerprint,
    component: RegistrationPage
  },
  {
    path: "/registeredSuccessfully",
    name: "Registration",
    short: "reg",
    mini: "LP",
    icon: Fingerprint,
    component: RegisterSuccess
  },
  {
    path: "/selectPlan",
    name: "Registration",
    short: "reg",
    mini: "LP",
    icon: Fingerprint,
    component: SelectPlan
  },
  {
    path: "/Updates",
    name: "Faq",
    short: "reg",
    mini: "LP",
    icon: Fingerprint,
    component: Faq
  },
  {
    redirect: true,
    path: "/",
    pathTo: sessionStorage.getItem("loggedUser")?'/admin/surveys':'/login',
    name: "Login Page"
  }
];

export default pagesRoutes;
