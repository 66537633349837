import axios from "axios";

import { createBrowserHistory } from "history";
import { adminErrors } from "../constants/errors";
import { toast } from "react-toastify";
export const hist = createBrowserHistory();
export const host = process.env.REACT_APP_API_URL;
export const host2 = process.env.REACT_APP_NEW_API_URL;
export const host4 = process.env.REACT_APP_V4_API_URL;
export const global_host = process.env.REACT_APP_API_BASE_URL;
// console.log(host)
export const domain = `${host}`;
export const domain2 = `${host2}`;
export const domain4 = `${host4}`;
export const global_domain = `${global_host}`;
export const setData = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const getData = (key) => {
  const data = JSON.parse(sessionStorage.getItem(key));
  return data ? data : null;
};

export const removeData = (key) => {
  sessionStorage.removeItem(key);
};

export const deleteUserData = () => {
  sessionStorage.clear();
};

export const axiosInstnace = axios.create({
  baseURL: domain,
});

export const axiosObj = axios.create({
  baseURL: domain2,
});

export const axios4 = axios.create({
  baseURL: domain4,
});

export const global_axios = axios.create({
  baseURL: global_domain,
});

export const axiosPreLogin = axios.create({
  baseURL: domain,
});

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

const successHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  const status = error && error.response && error.response.status;
  const msg =
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message;

  if (status === 401) {
    let timer;
    if (timer) {
      clearTimeout(timer);
    }
    window.sessionStorage.removeItem("loggedUser");
    const { data = {} } = (error && error.response) || {};
    if ((data && data.message) === "Incorrect Password") {
      toast.error(data.message, { toastId: 1 });
    } else {
      setTimeout(() => {
        // console.log(window.location.protocol, window.location.host)
        // window.location.href = (`${process.env.REACT_APP_ADMIN_URL}/home`)
        window.location.pathname = "/";
      }, 1000);
      toast.error(data && data.message, { toastId: 2 });
    }
  } else if (status === 500) {
    const { data = {} } = (error && error.response) || {};
    toast.error((data && data.message) || adminErrors.serverError, {
      toastId: 3,
    });
  } else if (status === 400) {
    toast.error(msg ? msg : adminErrors.noData, { toastId: 5 });
  } else if (status === 404) {
    toast.error(msg ? msg : adminErrors.noData, { toastId: 5 });
  } else {
    const { data = {} } = (error && error.response) || {};
    if (data && data.message) {
      toast.error(data.message, { toastId: 4 });
    } else {
      toast.error(adminErrors.apiFailure, { toastId: 4 });
    }
  }
  return Promise.reject({ ...error });
};

const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    const auth_token = JSON.parse(sessionStorage.getItem("loggedUser"));

    if (auth_token) {
      let date = new Date();
      request.headers["Authorization"] = "Bearer " + auth_token;

      setData("newSession", date.getTime());
    }
  }
  return request;
};

axiosPreLogin.interceptors.request.use((request) => requestHandler(request));

// axiosPreLogin.interceptors.response.use(
//   response => successHandler(response),
//   error => errorHandler(error)
// )

axiosInstnace.interceptors.request.use((request) => requestHandler(request));

axiosObj.interceptors.request.use((request) => requestHandler(request));

axios4.interceptors.request.use((request) => requestHandler(request));

global_axios.interceptors.request.use((request) => requestHandler(request));

axiosInstnace.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

axiosObj.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

axios4.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

global_axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export const isAuthenticated = () => !!getData("loggedUser");

export const getDateFormat = (date) => {
  let year = new Date(date).getFullYear();
  let month = new Date(date).getMonth() + 1;
  let day = new Date(date).getDate();

  month = month.toString().length === 2 ? month : `0${month}`;
  day = day.toString().length === 2 ? day : `0${day}`;

  let fullDate = `${year}-${month}-${day}`;
  return fullDate;
};

export const getEndDateFormat = (date) => {
  var currentDate = new Date(new Date(date).getTime() + 24 * 60 * 60 * 1000);
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();

  month = month.toString().length === 2 ? month : `0${month}`;
  day = day.toString().length === 2 ? day : `0${day}`;

  let fullDate = `${year}-${month}-${day}`;
  return fullDate;
};

export function getStorageData(key) {
  if (window && window.sessionStorage) {
    return JSON.parse(window.sessionStorage.getItem(key));
  }
  return null;
}

export function textTrim(text, len = 20) {
  if (text.length <= len) return text;
  return text.substring(0, len - 1) + "...";
}

export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}
