import Validator from 'is_js';
import { isEmpty } from 'lodash';
import { passwordRegex } from '../constants';
import { inputErrors } from '../../constants/errors';

const validateResetPassword = (data,validateCurrentPass ) => {
    let error={};

    if(Validator.empty(data.newPass)){
        error.newPass=inputErrors.required
    }

    if (Validator.empty(data.confirmPass)) {
        error.confirmPass = inputErrors.required
    }



    if (!Validator.empty(data.confirmPass) && (data.confirmPass.length < 8)) {
        error.confirmPass = inputErrors.passCharLength
    }

    if (!Validator.empty(data.newPass) && (data.newPass.length < 8)) {
        error.newPass = inputErrors.passCharLength
    }

    if (!Validator.empty(data.newPass) && (data.newPass.length > 32)) {
        error.newPass = inputErrors.maxCharlenthReached
    }

    const test1 = passwordRegex.test(data.newPass);
    const test2 = passwordRegex.test(data.currentPassword);
    if(!test1 && !Validator.empty(data.newPass) && (data.newPass.length >= 8) ){
        error.newPass=inputErrors.validPassFormat
    }

    if(!test1 && !Validator.empty(data.confirmPass) && (data.confirmPass.length >= 8) ){
        error.confirmPass=inputErrors.validPassFormat
    }

    if(!Validator.empty(data.newPass) && !Validator.empty(data.confirmPass) ){
        if((data.newPass) !== (data.confirmPass)){
            error.confirmPass = inputErrors.passNotMatched
        }
    }

    if(validateCurrentPass && validateCurrentPass.validateCurrentPass){
        if (Validator.empty(data.currentPassword)) {
            error.currentPassword = inputErrors.required
        }

        if(!test2 && !Validator.empty(data.currentPassword) && (data.currentPassword.length >= 8) ){
            error.currentPassword=inputErrors.validPassFormat
        }
    
        if(!test2 && !Validator.empty(data.currentPassword) && (data.currentPassword.length >= 8) ){
            error.currentPassword=inputErrors.validPassFormat
        }
    
        if (!Validator.empty(data.currentPassword) && (data.currentPassword.length < 8)) {
            error.currentPassword = inputErrors.passCharLength
        }
    
        if (!Validator.empty(data.currentPassword) && (data.currentPassword.length > 32)) {
            error.currentPassword = inputErrors.maxCharlenthReached
        }
    }
   
    return{
        isValid:isEmpty(error),
        error
    }
}

export default validateResetPassword