import "raf/polyfill";
import "core-js/es6/map";
import "core-js/es6/set";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { Route, Switch, withRouter, Router } from "react-router-dom";
import "babel-polyfill";
import Loader from "./components/Common/Loader";
import { indexRoutes, mainRoutes } from "./routes/index";
import AuthGuardImp from "./components/Common/AuthGuard";
import RestrictedImp from "./components/Common/RestrictedImp";

import "assets/animate/animate.min.css";
import "assets/scss/material-dashboard-pro-react.css";
import NotFound from "./views/Pages/NotFound";
import { ToastContainer } from "react-toastify";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import "assets/css/bootstrap.min.css";
import "assets/css/style.css";
import { useSelector } from "react-redux";

export const hist = createBrowserHistory();

export const RenderRoute = withRouter((props) => {
  const stopScroll = useSelector((state) => state.keppel_reducer.stopScroll);

  return (
    <div className={stopScroll ? "modalOpen" : ""}>
      <Loader />
      <Switch>
        <Route path="/restrict" component={RestrictedImp} />

        {indexRoutes.map((prop, key) => {
          return (
            <AuthGuardImp
              path={prop.path}
              component={prop.component}
              key={key}
              {...props}
            />
          );
        })}
        {mainRoutes.map((prop, key) => {
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        })}
        <Route component={NotFound} />
      </Switch>
      <ToastContainer
        autoClose={3000}
        position="top-right"
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
});

class Routes extends Component {
  render() {
    return (
      <Router history={hist}>
        <Route path="/" component={RenderRoute} />
      </Router>
    );
  }
}

export default Routes;
